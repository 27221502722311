/**
 * Configuration file for teeth component
 */
export const UPPER_RIGHT_JAW_TEETH = ['18','17','16','15','14','13','12','11'];
export const UPPER_RIGHT_JAW_TEETH_VALUES = [
    {
        label: '18',
        value: '18',
    },
    {
        label: '17',
        value: '17',
    },
    {
        label: '16',
        value: '16',
    },
    {
        label: '15',
        value: '15',
    },
    {
        label: '14',
        value: '14',
    },
    {
        label: '13',
        value: '13',
    },
    {
        label: '12',
        value: '12',
    },
    {
        label: '11',
        value: '11',
    }
];

export const UPPER_LEFT_JAW_TEETH = ['21','22','23','24','25','26','27','28'];
export const UPPER_LEFT_JAW_TEETH_VALUES = [
    {
        label: '21',
        value: '21',
    },
    {
        label: '22',
        value: '22',
    },
    {
        label: '23',
        value: '23',
    },
    {
        label: '24',
        value: '24',
    },
    {
        label: '25',
        value: '25',
    },
    {
        label: '26',
        value: '26',
    },
    {
        label: '27',
        value: '27',
    },
    {
        label: '28',
        value: '28',
    }
];

export const LOWER_RIGHT_JAW_TEETH = ['48','47','46','45','44','43','42','41'];
export const LOWER_RIGHT_JAW_TEETH_VALUES = [
    {
        label: '48',
        value: '48',
    },
    {
        label: '47',
        value: '47',
    },
    {
        label: '46',
        value: '46',
    },
    {
        label: '45',
        value: '45',
    },
    {
        label: '44',
        value: '44',
    },
    {
        label: '43',
        value: '43',
    },
    {
        label: '42',
        value: '42',
    },
    {
        label: '41',
        value: '41',
    }
];

export const LOWER_LEFT_JAW_TEETH = ['31','32','33','34','35','36','37','38'];
export const LOWER_LEFT_JAW_TEETH_VALUES = [
    {
        label: '31',
        value: '31',
    },
    {
        label: '32',
        value: '32',
    },
    {
        label: '33',
        value: '33',
    },
    {
        label: '34',
        value: '34',
    },
    {
        label: '35',
        value: '35',
    },
    {
        label: '36',
        value: '36',
    },
    {
        label: '37',
        value: '37',
    },
    {
        label: '38',
        value: '38',
    }
];

export const ALL_TEETH_ARRAY = ['18','17','16','15','14','13','12','11','21','22','23','24','25','26','27','28','48','47','46','45','44','43','42','41','31','32','33','34','35','36','37','38'];
export const UPPER_JAW_TEETH = ['18','17','16','15','14','13','12','11','21','22','23','24','25','26','27','28'];
export const LOWER_JAW_TEETH = ['48','47','46','45','44','43','42','41','31','32','33','34','35','36','37','38'];
export const WISDOM_TEETH =['18','28','38','48'];

export const UPPER_LEFT = 'upperLeft';
export const UPPER_RIGHT = 'upperRight';
export const LOWER_LEFT = 'lowerLeft';
export const LOWER_RIGHT = 'lowerRight';
export const ALL_TEETH = 'allTeeth';

export const JAWS_CHECKBOXES = [
    {
        type: UPPER_RIGHT,
        label: 'dental.upperRightJaw'
    },
    {
        type: UPPER_LEFT,
        label: 'dental.upperLeftJaw'
    },
    {
        type: LOWER_RIGHT,
        label: 'dental.lowerRightJaw'
    },
    {
        type: LOWER_LEFT,
        label: 'dental.lowerLeftJaw'
    },
    {
        type: ALL_TEETH,
        label: 'dental.allTeeth'
    }
]

export const contains = (arr, target) => target.every((v) => arr.includes(v));
