import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAuthStyles } from '../auth.style';
import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { authUser } from '../../../services/auth.service';
import { DevContext } from '../../../App';
import { getAuthValidationSchema } from '../../../utils/forms/validators/schemaValidators.yup';
import { Box, Container, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { encrypt } from '../../../utils/encrypt.utils';
import { addLoading, removeLoading } from '../../../store/uiSlice';
import useAuth from '../../../hooks/useAuth';

const SignIn = () => {
    const { t } = useTranslation();
    const isDevMode = useContext(DevContext);
    const classes = useAuthStyles();
    const auth = useAuth();
    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(()=>{
        auth.logout();
    }, []);

    const formInitialValues = {
        username: '',
        password: '',
    };

    const handleUserAuth = (res) => {
        if (res.status === 200 && res.data.token) {
            auth.setLoggedIn(res.data);
            dispatch(removeLoading());
            history.push('/dashboard/diagnocat');
        } else {
            //TODO remove and show error message on form
            alert(t('auth.signInFailed'));
            dispatch(removeLoading());
            
        }
    };

    // authenticate the user and redirect to dashboard.
    const onSubmitForm = (values, { setSubmitting }) => {
        setTimeout(async () => {
            setSubmitting(false);
            dispatch(addLoading());
            authUser(values.username, encrypt(values.password).toString())
            .then((res) => {
                handleUserAuth(res);
            }).catch(err =>{
                console.log(err);
                alert(t('auth.signInFailed'));
                
                dispatch(removeLoading());
            })
        }, 500);
    };

    //TODO
    const isDoctor = () => {
        return isDevMode;
    };

    return (
        <Container maxWidth='xs'>
        <Paper elevation={3} className={classes.paper}>
            <Box padding="1rem">
  
                <Typography component='h1' variant='h5'>
                    {t('auth.signIn')} | {t('auth.diagnocalAIPortal')}
                </Typography>
                
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={getAuthValidationSchema(t('forms.validators.required'))}
                    onSubmit={onSubmitForm}>
                    {({ submitForm, isSubmitting, touched, errors }) => (
                        <Form className={classes.form}>
                            <Field
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                id='username'
                                label={t('forms.username')}
                                name='username'
                                autoComplete='username'
                                autoFocus
                                component={TextField}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Field
                                variant='outlined'
                                margin='normal'
                                required
                                fullWidth
                                name='password'
                                label={t('forms.password')}
                                type='password'
                                id='password'
                                autoComplete='current-password'
                                component={TextField}
                                InputLabelProps={{ shrink: true }}

                            />
                            {/*{isDevMode && (*/}
                            {/*    <FormControlLabel*/}
                            {/*        control={<Checkbox value='remember' color='primary' />}*/}
                            {/*        label={t('auth.rememberMe')}*/}
                            {/*    />*/}
                            {/*)}*/}

                            {isDevMode ? (
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    className={classes.submit}
                                    disabled={isSubmitting}
                                    onClick={submitForm}>
                                    {t('auth.signIn')}
                                </Button>
                            ) : (
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    className={classes.submit}
                                    disabled={isSubmitting}
                                    onClick={submitForm}>
                                    {t('auth.signIn')}
                                </Button>
                            )}
                        </Form>
                    )}
                </Formik>
                

                <Grid container>
                    {/*TODO - might be irrelevant*/}
                    {isDevMode && (
                        <>
                            <Grid item xs>
                                <Link href='#' variant='body2'>
                                    {t('auth.forgotPassword')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='sign-up' variant='body2'>
                                    {t('auth.dontHaveAccount')}
                                </Link>
                            </Grid>
                        </>
                    )}
                </Grid>
                </Box>
        </Paper>
        </Container>    
    );
};

export default SignIn;
