import React from 'react';
import UploadFileDialog from './UploadFileDialog';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DIALOG_TYPES } from '../../../assets/conf/diagnocat.configuration';
import { useTranslation } from 'react-i18next';

const UploadFiles = (props) => {
    const { t } = useTranslation();
    const [activeDialog, setActiveDialog] = React.useState(null);

    const handleDialogOpen = (dialogName) => {
        setActiveDialog(dialogName);
    };

    const handleDialogClose = () => {
        setActiveDialog(null);
    };

    return (
        <>
            <UploadFileDialog
                activeDialog={activeDialog}
                dialogClosed={handleDialogClose}
                choosePatient={props.redirectPatient ? props.redirectPatient : () => {}}
            />
            <Button
                variant='outlined'
                color='default'
                size='medium'
                startIcon={<CloudUploadIcon />}
                onClick={() => handleDialogOpen(DIALOG_TYPES.UPLOAD)}>
                {t('containers.diagnocat.uploads.uploadFile')}
            </Button>
        </>
    );
};

export default UploadFiles;
