import React, { useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useLayoutStyles } from './DashboardLayout.style';
import AppNavbar from '../../components/UI/navbar/AppNavbar';
import AppDrawer from '../../components/UI/drawer/AppDrawer';
import AppBreadcrumbs from '../../components/UI/breadcrumbs/AppBreadcrumbs';
import DashboardRouter from '../../routing/DashboardRouter';
import { DevContext } from '../../App';
import { useDispatch } from 'react-redux';
import AppFooter from '../../components/UI/footer/AppFooter';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MOBILE_QUERY } from '../../utils/constants';
import withErrorHandler from '../../hoc/withErrorHandler';

const DashboardLayout = () => {
    const classes = useLayoutStyles();
    const isDevMode = useContext(DevContext);
    const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);
    return (
        <React.Fragment>
            <AppNavbar/>
            <Box className={classes.root}>
                <main className={ classes.content }>
                    {/*{isDevMode && <RoleSwitch />}*/}
                    <Box paddingTop={2}>
                        <DashboardRouter />
                        {/*<WithLoadingAppRouter isLoading={loading} />*/}
                    </Box>
                </main>
                <AppFooter/>
            </Box>
        </React.Fragment>
    );
};

export default withErrorHandler(DashboardLayout);
