import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { FormGroup, FormLabel } from '@material-ui/core';
import * as TEETH from '../../../../../assets/conf/teeth.configuration';
import JawSideCheckbox from './JawSideCheckbox';
import JawTeethCheckboxes from './JawTeethCheckboxes';
import * as _ from 'lodash';
import withErrorHandler from '../../../../../hoc/withErrorHandler';
import { useTranslation } from 'react-i18next';
import { contains } from '../../../../../assets/conf/teeth.configuration';

/**
 * Teeth selection form for order reports forms.
 * The selection is divided to 4 parts - upper left, upper right, bottom left and bottom right,
 * and there are checkboxes that allow selection of all teeth in each part.
 */
const TeethSelection = (props) => {
    const { t } = useTranslation();

    function setAllTeethIfOtherJawsFull(jaw1, jaw2, jaw3) {
        if (getFormValue(jaw1) && getFormValue(jaw2) && getFormValue(jaw3)) {
            props.setFieldValue(getFullPropName(TEETH.ALL_TEETH), true);
        }
    }

    const handleTeethChange = (event) => {
        //update the teeth checkbox
        props.handleChange(event);

        //if this teeth check "completes" a jaw - check the jaw checkbox or allTeeth checkbox
        if (event.target.checked) {
            if (
                TEETH.UPPER_RIGHT_JAW_TEETH.includes(event.target.value) &&
                contains(
                    props.teethList,
                    _.without(TEETH.UPPER_RIGHT_JAW_TEETH, event.target.value)
                )
            ) {
                props.setFieldValue(getFullPropName(TEETH.UPPER_RIGHT), true);
                setAllTeethIfOtherJawsFull(TEETH.UPPER_LEFT, TEETH.LOWER_LEFT, TEETH.LOWER_RIGHT);
            } else if (
                TEETH.UPPER_LEFT_JAW_TEETH.includes(event.target.value) &&
                contains(props.teethList, _.without(TEETH.UPPER_LEFT_JAW_TEETH, event.target.value))
            ) {
                props.setFieldValue(getFullPropName(TEETH.UPPER_LEFT), true);
                setAllTeethIfOtherJawsFull(TEETH.UPPER_RIGHT, TEETH.LOWER_LEFT, TEETH.LOWER_RIGHT);
            } else if (
                TEETH.LOWER_RIGHT_JAW_TEETH.includes(event.target.value) &&
                contains(
                    props.teethList,
                    _.without(TEETH.LOWER_RIGHT_JAW_TEETH, event.target.value)
                )
            ) {
                props.setFieldValue(getFullPropName(TEETH.LOWER_RIGHT), true);
                setAllTeethIfOtherJawsFull(TEETH.UPPER_LEFT, TEETH.UPPER_RIGHT, TEETH.LOWER_LEFT);
            } else if (
                TEETH.LOWER_LEFT_JAW_TEETH.includes(event.target.value) &&
                contains(props.teethList, _.without(TEETH.LOWER_LEFT_JAW_TEETH, event.target.value))
            ) {
                props.setFieldValue(getFullPropName(TEETH.LOWER_LEFT), true);
                setAllTeethIfOtherJawsFull(TEETH.UPPER_LEFT, TEETH.UPPER_RIGHT, TEETH.LOWER_RIGHT);
            }
        }

        //if this teeth uncheck "uncompletes" a jaw - uncheck the jaw checkbox or allTeeth checkbox
        else {
            if (TEETH.UPPER_RIGHT_JAW_TEETH.includes(event.target.value)) {
                props.setFieldValue(getFullPropName(TEETH.UPPER_RIGHT), false);
            } else if (TEETH.UPPER_LEFT_JAW_TEETH.includes(event.target.value)) {
                props.setFieldValue(getFullPropName(TEETH.UPPER_LEFT), false);
            } else if (TEETH.LOWER_RIGHT_JAW_TEETH.includes(event.target.value)) {
                props.setFieldValue(getFullPropName(TEETH.LOWER_RIGHT), false);
            } else if (TEETH.LOWER_LEFT_JAW_TEETH.includes(event.target.value)) {
                props.setFieldValue(getFullPropName(TEETH.LOWER_LEFT), false);
            }
            props.setFieldValue(getFullPropName(TEETH.ALL_TEETH), false);
        }
    };

    function setJawCheckboxes(value) {
        props.setFieldValue(getFullPropName(TEETH.UPPER_LEFT), value);
        props.setFieldValue(getFullPropName(TEETH.UPPER_RIGHT), value);
        props.setFieldValue(getFullPropName(TEETH.LOWER_LEFT), value);
        props.setFieldValue(getFullPropName(TEETH.LOWER_RIGHT), value);
    }

    function handleAllTeethUnchecked() {
        props.teethChanged([]);
        setJawCheckboxes(false);
    }

    function handleAllTeethChecked() {
        props.teethChanged(TEETH.ALL_TEETH_ARRAY);
        setJawCheckboxes(true);
    }

    function handleJawUnchecked(teeth, jawTeeth) {
        teeth = _.difference(teeth, jawTeeth); //_.pullAll mutates array
        props.teethChanged(teeth);
        props.setFieldValue(getFullPropName(TEETH.ALL_TEETH), false);
        return teeth;
    }

    function handleJawChecked(teeth, jawTeeth, otherCheckboxes) {
        //union takes only not duplicated values
        teeth = _.union(teeth, jawTeeth);
        props.teethChanged(teeth);
        if (otherCheckboxes) {
            props.setFieldValue(getFullPropName(TEETH.ALL_TEETH), true);
        }
    }

    function getFormValue(value) {
        return props.parentFormName
            ? props.values[props.parentFormName][value]
            : props.values[value];
    }

    // return the full prop name, for nested fields
    function getFullPropName(propName) {
        return props.parentFormName ? props.parentFormName + '.' + propName : propName;
    }

    const handleJawCheckboxChange = (event) => {
        let teeth = getFormValue('teethList');

        //event.target.name would be parentFormName.upperLeft if form is
        let name = props.parentFormName ? event.target.name.split('.')[1] : event.target.name;

        //if unchecked
        if (!event.target.checked) {
            switch (name) {
                case TEETH.UPPER_LEFT:
                    teeth = handleJawUnchecked(teeth, TEETH.UPPER_LEFT_JAW_TEETH);
                    break;
                case TEETH.UPPER_RIGHT:
                    teeth = handleJawUnchecked(teeth, TEETH.UPPER_RIGHT_JAW_TEETH);
                    break;
                case TEETH.LOWER_LEFT:
                    teeth = handleJawUnchecked(teeth, TEETH.LOWER_LEFT_JAW_TEETH);
                    break;
                case TEETH.LOWER_RIGHT:
                    teeth = handleJawUnchecked(teeth, TEETH.LOWER_RIGHT_JAW_TEETH);
                    break;
                case TEETH.ALL_TEETH:
                    handleAllTeethUnchecked();
                    break;
                default:
                    console.log('default');
                    break;
            }
        }

        //if checked
        else {
            switch (name) {
                case TEETH.UPPER_LEFT:
                    handleJawChecked(
                        teeth,
                        TEETH.UPPER_LEFT_JAW_TEETH,
                        getFormValue(TEETH.UPPER_RIGHT) &&
                            getFormValue(TEETH.LOWER_RIGHT) &&
                            getFormValue(TEETH.LOWER_LEFT)
                    );
                    break;
                case TEETH.UPPER_RIGHT:
                    handleJawChecked(
                        teeth,
                        TEETH.UPPER_RIGHT_JAW_TEETH,
                        getFormValue(TEETH.UPPER_LEFT) &&
                            getFormValue(TEETH.LOWER_RIGHT) &&
                            getFormValue(TEETH.LOWER_LEFT)
                    );
                    break;
                case TEETH.LOWER_LEFT:
                    handleJawChecked(
                        teeth,
                        TEETH.LOWER_LEFT_JAW_TEETH,
                        getFormValue(TEETH.UPPER_LEFT) &&
                            getFormValue(TEETH.UPPER_RIGHT) &&
                            getFormValue(TEETH.LOWER_RIGHT)
                    );
                    break;
                case TEETH.LOWER_RIGHT:
                    handleJawChecked(
                        teeth,
                        TEETH.LOWER_RIGHT_JAW_TEETH,
                        getFormValue(TEETH.UPPER_LEFT) &&
                            getFormValue(TEETH.UPPER_RIGHT) &&
                            getFormValue(TEETH.LOWER_LEFT)
                    );
                    break;
                case TEETH.ALL_TEETH:
                    handleAllTeethChecked();
                    break;
                default:
                    break;
            }
        }
        props.handleChange(event);
    };

    return (
        <React.Fragment>
            <FormControl component='fieldset' style={{ display: 'flex', alignItems: 'center' }}>
                <FormLabel component='legend' style={{ marginBottom: '1em' }}>
                    {t('globals.selectTeeth')}
                </FormLabel>
                <FormLabel component='legend'>{t('dental.maxilla')}</FormLabel>
                <FormGroup style={{ flexDirection: 'row' }}>
                    <JawTeethCheckboxes
                        teethValues={TEETH.UPPER_RIGHT_JAW_TEETH_VALUES}
                        availableTeeth={props.availableTeeth}
                        disableTeeth={props.disableTeeth}
                        values={props.values}
                        teethList={props.teethList}
                        teethListName={getFullPropName('teethList')}
                        teethChanged={handleTeethChange}
                        labelPlacement='bottom'
                    />
                    <JawTeethCheckboxes
                        teethValues={TEETH.UPPER_LEFT_JAW_TEETH_VALUES}
                        availableTeeth={props.availableTeeth}
                        disableTeeth={props.disableTeeth}
                        values={props.values}
                        teethList={props.teethList}
                        teethListName={getFullPropName('teethList')}
                        teethChanged={handleTeethChange}
                        labelPlacement='bottom'
                    />
                </FormGroup>
                <FormLabel component='legend'>{t('dental.mandible')}</FormLabel>
                <FormGroup style={{ flexDirection: 'row' }}>
                    <JawTeethCheckboxes
                        teethValues={TEETH.LOWER_RIGHT_JAW_TEETH_VALUES}
                        availableTeeth={props.availableTeeth}
                        disableTeeth={props.disableTeeth}
                        values={props.values}
                        teethList={props.teethList}
                        teethListName={getFullPropName('teethList')}
                        teethChanged={handleTeethChange}
                        labelPlacement='top'
                    />
                    <JawTeethCheckboxes
                        teethValues={TEETH.LOWER_LEFT_JAW_TEETH_VALUES}
                        availableTeeth={props.availableTeeth}
                        disableTeeth={props.disableTeeth}
                        values={props.values}
                        teethList={props.teethList}
                        teethListName={getFullPropName('teethList')}
                        teethChanged={handleTeethChange}
                        labelPlacement='top'
                    />
                </FormGroup>
                {props.isCheckboxes && (
                    <FormGroup style={{ flexDirection: 'row' }}>
                        {TEETH.JAWS_CHECKBOXES.map((jawCheckbox) => (
                            <JawSideCheckbox
                                key={jawCheckbox.type}
                                type={getFullPropName(jawCheckbox.type)}
                                label={t(jawCheckbox.label)}
                                checkboxChanged={handleJawCheckboxChange}
                                checked={getFormValue(jawCheckbox.type)}
                            />
                        ))}
                    </FormGroup>
                )}
            </FormControl>
        </React.Fragment>
    );
};

export default withErrorHandler(TeethSelection);
