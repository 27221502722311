import React from 'react';
import { Form, Formik } from 'formik';
import FormDialogButtons from '../../../../components/UI/dialog/FormDialogButtons';
import TeethSelection from './teethSelection/TeethSelection';
import { ANALYSIS_TYPES } from '../../../../assets/conf/diagnocat.configuration';
import { ThirdMolarValidationSchema } from '../../../../utils/forms/validators/schemaValidators.yup';
import { useTranslation } from 'react-i18next';
import { WISDOM_TEETH } from '../../../../assets/conf/teeth.configuration';

const formInitialValues = {
    teethList: [],
};

const ThirdMolarReportForm = (props) => {
    const { t } = useTranslation();

    const onSubmitForm = (values, { setSubmitting }) => {
        setTimeout(() => {
            setSubmitting(false);
            let analysisRequest = {
                analysis_type: ANALYSIS_TYPES.MOLAR,
                analysis_params: {
                    roi:{
                        teeth: values.teethList.map((teeth) => parseInt(teeth)),
                    }
                }
            }
            props.formSubmitted(analysisRequest);
        }, 500);
    };


    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={ThirdMolarValidationSchema}
            onSubmit={onSubmitForm}>
            {({ submitForm, isSubmitting, isValid, dirty, values, setFieldValue, handleChange }) => (
                <Form>
                    <TeethSelection
                        values={values}
                        handleChange ={handleChange}
                        setFieldValue={setFieldValue}
                        teethList = {values.teethList}

                        disableTeeth
                        availableTeeth={WISDOM_TEETH}
                    />

                    <FormDialogButtons
                        submitButtonClicked={submitForm}
                        isSubmitting={isSubmitting}
                        isSubmitDisabled={!(isValid && dirty)}
                        closeButtonClicked={props.handleClose}
                        submitTitle={t('globals.submit')}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default ThirdMolarReportForm;
