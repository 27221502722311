import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormDialog from '../../../components/UI/dialog/FormDialog';
import SendLinkForm from './SendLinkForm';
import { useDialog } from '../../../hooks/useDialog';

const SendLinkToDoctor = (props) => {
    const { t } = useTranslation();
    const { dialogOpen, handleDialogOpen, handleDialogClose } = useDialog()

    return (
        <React.Fragment>
            <FormDialog
                title={t('containers.diagnocat.sendLinkToDoctor')}
                open={dialogOpen}
                maxWidth='md'
                handleClose={handleDialogClose}>
                {/*<Typography>*/}
                {/*    The doctor's email is required in order to create link for diagnocat dynamic*/}
                {/*    report. The link won't be sent to the doctor's email.*/}
                {/*</Typography>*/}
                <SendLinkForm formSent={handleDialogClose} sendLinkToDoctor={props.sendLinkToDoctor}></SendLinkForm>
            </FormDialog>
            <Button variant='outlined' color='default' onClick={handleDialogOpen}>
                {t('containers.diagnocat.sendLinkToDoctor')}
            </Button>
        </React.Fragment>
    );
};

export default SendLinkToDoctor;
