export const sliceThickness = [
    {
        value: '0.25',
        label: '0.25',
    },
    {
        value: '0.5',
        label: '0.5',
    },
    {
        value: '1',
        label: '1',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '5',
        label: '5',
    },
];

export const sliceStep = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '1.5',
        label: '1.5',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '2.5',
        label: '2.5',
    },
    {
        value: '3',
        label: '3',
    },
];

export const stlRadioButtonsOptions = [
    {
        label: 'containers.diagnocat.reportsDialogs.jawSingleFile',
        value: 'jawSingleFile',
    },
    {
        label: 'containers.diagnocat.reportsDialogs.jawSeparateFiles',
        value: 'jawSeparateFiles',
    },
    {
        label: 'containers.diagnocat.reportsDialogs.teethSeparateFiles',
        value: 'teethSeparateFiles',
    },
];
