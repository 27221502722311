import { Avatar, Card, Paper, Typography, Link } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import { logoutUser, setLoggedInUser } from '../../../store/globalSlice';
import { useAuthStyles } from '../auth.style';

const Logout = () =>{
    const classes = useAuthStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const auth = useAuth();
    
    useEffect(()=>{
        auth.logout();
    }, []);

    return (
        <div className={classes.paper}>
   
            <Typography component='p' variant='p'>
                {t('auth.logoutSuccess')}
            </Typography>

            <Link href='sign-in' variant='body2'>
                {t('auth.signIn')}
            </Link>
        </div>
    )
}

export default Logout;