import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import FormDialogButtons from '../../../../components/UI/dialog/FormDialogButtons';
import { stlRadioButtonsOptions } from '../../../../utils/forms/options/diagnocatReport.options';
import TeethSelection from './teethSelection/TeethSelection';
import { STLValidationSchema } from '../../../../utils/forms/validators/schemaValidators.yup';
import { RadioGroup } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { ANALYSIS_TYPES } from '../../../../assets/conf/diagnocat.configuration';
import JawTeethCheckboxes from './teethSelection/JawTeethCheckboxes';

const formInitialValues = {
    stlOption: stlRadioButtonsOptions[0].value,
    teethList: [],
    upperLeft: false,
    upperRight: false,
    lowerLeft: false,
    lowerRight: false,
    allTeeth: false,
};

const STLReportForm = (props) => {
    const { t } = useTranslation();

    const onSubmitForm = (values, { setSubmitting }) => {
        setTimeout(() => {
            setSubmitting(false);
            let analysisRequest = {
                analysis_type: ANALYSIS_TYPES.STL,
                analysis_params: {
                    roi: {
                        teeth: values.teethList.map((teeth) => parseInt(teeth)),
                        merge_stl: values.stlOption === 'jawSingleFile' ? true : false,
                    },
                },
            };
            props.formSubmitted(analysisRequest);
        }, 500);
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={STLValidationSchema}
            onSubmit={onSubmitForm}>
            {({
                submitForm,
                isSubmitting,
                isValid,
                dirty,
                values,
                handleChange,
                setFieldValue,
            }) => (
                <Form>
                    <Field component={RadioGroup} name='stlOption'>
                        {stlRadioButtonsOptions.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                label={t(option.label)}
                                control={<Radio />}
                            />
                        ))}
                    </Field>
                    <TeethSelection
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        teethList = {values.teethList}

                        isCheckboxes
                        teethChanged={(value) => setFieldValue('teethList', value)}
                    />

                    <FormDialogButtons
                        submitButtonClicked={submitForm}
                        isSubmitting={isSubmitting}
                        isSubmitDisabled={!(isValid && dirty)}
                        closeButtonClicked={props.handleClose}
                        submitTitle={t('globals.submit')}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default STLReportForm;
