import React from 'react';
import { Divider, Button, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Form, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { AddCircle } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectTypes } from '../../../store/diagnocat/diagnocatSlice';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    // pdfLinks: Yup.array().of(
    //     Yup.object().shape({
    //         type: Yup.string().required('First name is required'),
    //         url: Yup.string().required('Last name is required'),
    //     })
    // ),
});

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    field: {
        margin: theme.spacing(1),
        minWidth: '200px',
    },
}));

const SendLinkForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const reportTypes = useSelector(selectTypes);

    let initialValues = {
        email: '',
        appointmentId: '',
        // pdfLinks: [
        //     {
        //         id: Math.random(),
        //         type: '',
        //         url: '',
        //     },
        // ],
    };

    let onFormSubmit = (values, { setSubmitting }) => {
        setSubmitting(false);
        props.formSent();
        // let pdfLinksArray = values.pdfLinks.map((pdfLink) => ({
        //     url: pdfLink.url,
        //     type_id: pdfLink.type,
        // }));
        setTimeout(() => {
            props.sendLinkToDoctor(values.email);
            // props.sendLinkToDoctor(values.email, pdfLinksArray);
        }, 500);
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}>
                {({ values, touched, errors, handleChange, handleBlur, isValid, dirty }) => (
                    <Form noValidate autoComplete='off'>
                        <Box margin={1}>
                            <TextField
                                name='email'
                                type='text'
                                label={t('forms.doctorEmail')}
                                required
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                    getIn(touched, 'email') && getIn(errors, 'email')
                                        ? getIn(errors, 'email')
                                        : ''
                                }
                                error={Boolean(getIn(touched, 'email') && getIn(errors, 'email'))}
                            />
                        </Box>
                        {/*<FieldArray name='pdfLinks'>*/}
                        {/*    {({ push, remove }) => (*/}
                        {/*        <div>*/}
                        {/*            {values.pdfLinks.map((p, index) => {*/}
                        {/*                const type = `pdfLinks[${index}].type`;*/}
                        {/*                const touchedType = getIn(touched, type);*/}
                        {/*                const errorType = getIn(errors, type);*/}

                        {/*                const url = `pdfLinks[${index}].url`;*/}
                        {/*                const touchedPDFLink = getIn(touched, url);*/}
                        {/*                const errorPDFLink = getIn(errors, url);*/}

                        {/*                return (*/}
                        {/*                    <div key={p.id}>*/}
                        {/*                        <TextField*/}
                        {/*                            className={classes.field}*/}
                        {/*                            margin='normal'*/}
                        {/*                            variant='outlined'*/}
                        {/*                            label='Report Type'*/}
                        {/*                            name={type}*/}
                        {/*                            value={p.type}*/}
                        {/*                            required*/}
                        {/*                            select*/}
                        {/*                            helperText={*/}
                        {/*                                touchedType && errorType ? errorType : ''*/}
                        {/*                            }*/}
                        {/*                            error={Boolean(touchedType && errorType)}*/}
                        {/*                            onChange={handleChange}*/}
                        {/*                            onBlur={handleBlur}>*/}
                        {/*                            {reportTypes.map((option) => (*/}
                        {/*                                <MenuItem key={option.id} value={option.id}>*/}
                        {/*                                    {option.name}*/}
                        {/*                                </MenuItem>*/}
                        {/*                            ))}*/}
                        {/*                        </TextField>*/}
                        {/*                        <TextField*/}
                        {/*                            className={classes.field}*/}
                        {/*                            margin='normal'*/}
                        {/*                            variant='outlined'*/}
                        {/*                            label='PDF Link'*/}
                        {/*                            name={url}*/}
                        {/*                            value={p.url}*/}
                        {/*                            required*/}
                        {/*                            helperText={*/}
                        {/*                                touchedPDFLink && errorPDFLink*/}
                        {/*                                    ? errorPDFLink*/}
                        {/*                                    : ''*/}
                        {/*                            }*/}
                        {/*                            error={Boolean(touchedPDFLink && errorPDFLink)}*/}
                        {/*                            onChange={handleChange}*/}
                        {/*                            onBlur={handleBlur}*/}
                        {/*                        />*/}
                        {/*                        <IconButton*/}
                        {/*                            color='primary'*/}
                        {/*                            aria-label='add pdf link'*/}
                        {/*                            className={classes.button}*/}
                        {/*                            margin='normal'*/}
                        {/*                            onClick={() =>*/}
                        {/*                                push({*/}
                        {/*                                    id: Math.random(),*/}
                        {/*                                    type: '',*/}
                        {/*                                    url: '',*/}
                        {/*                                })*/}
                        {/*                            }>*/}
                        {/*                            <AddCircle />*/}
                        {/*                        </IconButton>*/}
                        {/*                        {values.pdfLinks.length > 1 && (*/}
                        {/*                            <IconButton*/}
                        {/*                                color='secondary'*/}
                        {/*                                aria-label='add pdf link'*/}
                        {/*                                className={classes.button}*/}
                        {/*                                margin='normal'*/}
                        {/*                                onClick={() => remove(index)}>*/}
                        {/*                                <RemoveCircleIcon />*/}
                        {/*                            </IconButton>*/}
                        {/*                        )}*/}
                        {/*                    </div>*/}
                        {/*                );*/}
                        {/*            })}*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</FieldArray>*/}
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                        <Button
                            className={classes.button}
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={!isValid || !dirty}>
                            {/*disabled={!isValid || values.pdfLinks.length === 0 || !dirty}>*/}
                            submit
                        </Button>
                        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SendLinkForm;
