import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#D0DF00'
        },
     
        background: {
            default: '#F4F4F4',
        },

    },
});

export default theme;
