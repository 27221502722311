import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const FormDialog = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='form-dialog-title'
            fullWidth
            xs={{textAlign: "center"}}
            maxWidth={props.maxWidth? props.maxWidth: 'xs'}>
            <IconButton
                aria-label="close"
                onClick={props.handleClose}
                style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle id='form-dialog-title'>{props.title}</DialogTitle>
            
            <DialogContent>
                <DialogContentText>{props.contextText}</DialogContentText>
                {props.children}
            </DialogContent>
        </Dialog>
    );
};

export default FormDialog;
