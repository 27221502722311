import React, { useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    CHECKED_BY_PROCESSING_TEAM,
    CHECKED_BY_RADIOLOGIST,
    DIAGNOCAT_APPOINTMENT_STATUSES,
} from '../../../assets/conf/diagnocat.configuration';
import AlertDialog from '../../../components/UI/dialog/AlertDialog';
import { updateAppointmentStatus } from '../../../store/diagnocat/diagnocatSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withErrorHandler from '../../../hoc/withErrorHandler';

const DiagnocatStatusCheckboxes = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.selectedAppointment.diagnocat_status) {
            // all the statuses from 1 to the current status
            // for example: if the appointment status is 2 (checked by radiologist),
            // then we set also 1 (checked by processing team)
            let allStatuses = Array.from(
                { length: props.selectedAppointment.diagnocat_status },
                (_, i) => i + 1
            );
            setActiveCheckboxes(allStatuses);
        }
    }, []);

    //TODO: create hoc - with alertdialog
    const [open, setOpen] = React.useState(false);
    const [currentCheckbox, setCurrentCheckbox] = React.useState(null);
    const [activeCheckboxes, setActiveCheckboxes] = React.useState([]);

    const handleAccept = () => {
        setOpen(false);
        let found = activeCheckboxes.includes(currentCheckbox);
        // O O - status is null
        // V O - status is set to 'CHECKED_BY_PROCESSING_TEAM'
        // V V - status is set to 'CHECKED_BY_RADIOLOGIST'
        if (found) {
            setActiveCheckboxes(activeCheckboxes.filter((x) => x !== currentCheckbox));

            currentCheckbox == CHECKED_BY_PROCESSING_TEAM
                ? dispatch(updateAppointmentStatus(props.selectedAppointment.id, null))
                : dispatch(
                      updateAppointmentStatus(
                          props.selectedAppointment.id,
                          CHECKED_BY_PROCESSING_TEAM
                      )
                  );
        } else {
            setActiveCheckboxes([...activeCheckboxes, currentCheckbox]);
            dispatch(updateAppointmentStatus(props.selectedAppointment.id, currentCheckbox));
        }
        //TODO - will show also when error
        alert(t('containers.diagnocat.statusSuccessfullyUpdated'));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCheck = (checkboxValue) => {
        setOpen(true);
        setCurrentCheckbox(checkboxValue);
    };

    const isDisabled = (value) => {
        switch (value) {
            case CHECKED_BY_PROCESSING_TEAM:
                return activeCheckboxes.includes(CHECKED_BY_RADIOLOGIST) ? true : false;
            case CHECKED_BY_RADIOLOGIST:
                return activeCheckboxes.includes(CHECKED_BY_PROCESSING_TEAM) ? false : true;
            default:
                return false;
        }
    };

    return (
        <React.Fragment>
            <AlertDialog
                open={open}
                cancelClicked={handleClose}
                acceptClicked={handleAccept}
                title={'globals.areYouSure'}
            />
            <FormGroup row>
                <div>
                    {DIAGNOCAT_APPOINTMENT_STATUSES.map((option) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={activeCheckboxes.includes(option.value)}
                                    disabled={isDisabled(option.value)}
                                    onChange={() => handleCheck(option.value)}
                                />
                            }
                            label={option.label}
                            key={option.value}
                        />
                    ))}
                </div>
            </FormGroup>
        </React.Fragment>
    );
};
export default withErrorHandler(DiagnocatStatusCheckboxes);
