import { Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import AlertDialog from '../../../components/UI/dialog/AlertDialog';
import withErrorHandler from '../../../hoc/withErrorHandler';
import IconButton from '@material-ui/core/IconButton';

//TODO create generic one for this and DeletePatientButton
const DeleteButton = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const deleteButtonClicked = () => {
        setOpen(true);
    };

    const acceptClicked = () => {
        setOpen(false);
        props.acceptClicked();
    };

    return (
        <React.Fragment>
            <AlertDialog
                open={open}
                cancelClicked={handleClose}
                acceptClicked={acceptClicked}
                title={'globals.areYouSure'}
            />
            {props.isIcon ? (
                <IconButton color='default' onClick={deleteButtonClicked}>
                    <DeleteIcon />
                </IconButton>
            ) : (
                <Button
                    variant='outlined'
                    color='default'
                    startIcon={<DeleteIcon />}
                    onClick={deleteButtonClicked}>
                    {props.name}
                </Button>
            )}
        </React.Fragment>
    );
};

export default withErrorHandler(DeleteButton);
