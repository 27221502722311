import {
    CTDENT_DIAGNOCAT_SAVE_LINK,
    DIAGNOCAT_USERS,
} from '../../assets/conf/api-endpoints';
import instance from '../../axios/diagnocatAxios';

/**
 * update appoinment data to save link
 * @param {*} appointmentId 
 * @param {*} link 
 * @returns 
 */
export async function updateAppointmentLink(appointmentId, link) {
    let req = {
        id: appointmentId,
        diagnocat_link: link,
    };

    const response = await instance.post(CTDENT_DIAGNOCAT_SAVE_LINK, req);
    return response.data;
}

/**
 * get list of all radiologists
 */
export async function getAllRadiologists() {

    const response = await instance.get(`${DIAGNOCAT_USERS}?role_key=RADIOLOGIST`);
    return response.data;
}

