import ScannerIcon from '@material-ui/icons/Scanner';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import React from 'react';
export const routes = [];
/**
 * Used for Diagnocat mode - in order to have a standalone diagnocat system (development)
 */
const LazyDiagnocatDashboard = React.lazy(() =>
    import('../../containers/diagnocat/DiagnocatDashboard')
);

export const diagnocatRoutes = [
    {
        path: '/diagnocat',
        layout: '/dashboard',
        name: 'routes.team.diagnocat',
        icon: ScannerIcon,
        component: LazyDiagnocatDashboard,
        isOnMenu: true,
    },
    {
        path: '/logout',
        layout: '/auth',
        name: 'routes.auth.logout',
        icon: ExitToAppOutlinedIcon,
        component: LazyDiagnocatDashboard,
        isOnMenu: true,
    },
];
