import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './globalSlice';
import diagnocatReducer from './diagnocat/diagnocatSlice';
import uploadReducer from './diagnocat/uploadSlice';
import uiReducer from './uiSlice';

console.log("slice",diagnocatReducer)
const store = configureStore({
    reducer: {
        diagnocat: diagnocatReducer,

        global: globalReducer,
        upload: uploadReducer,
        ui: uiReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
        }),
});

export default store;
