import React from 'react';
import { Paper } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import DeleteButton from './DeleteButton';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../../store/globalSlice';
import withErrorHandler from '../../../hoc/withErrorHandler';
import { RADIOLOGIST_ROLE } from '../../../assets/conf/diagnocat.configuration';
import { useCtDentStyle } from '../ct-dent.style';

const DiagnocatAnalysis = (props) => {
    const { t } = useTranslation();
    const classes = useCtDentStyle();
    const loggedInUser = useSelector(selectLoggedInUser);

    const onDeleteAnalysisAccepted = () => {
        props.deleteAnalysis(props.analysis.uid);
    };

    return (
        <Paper style={{ textAlign: 'left', marginTop: 10, padding: 10 }}>
            <Box display='flex'>
                <Box display='flex' flexDirection='column' flexGrow={1}>
                    <Typography>Type: {props.analysis.analysis_type}</Typography>
                    <Typography>Created at: {props.analysis.created_at}</Typography>
                    {!props.analysis.complete && !props.analysis.error && (
                        <Typography>{t('containers.diagnocat.analysisNotCompleted')}</Typography>
                    )}
                    {props.analysis.error && (
                        <Typography>{t('errors.errorCreatingAnalysis')} {props.analysis.error}</Typography>
                    )}
                    {props.analysis.pdf_url && (
                        <Typography>
                            PDF: <Link target='_blank' href={props.analysis.pdf_url} variant='body2' className={classes.link}>
                                {t('containers.diagnocat.pdfLink')}
                            </Link>
                        </Typography>
                    )}
                    {props.analysis.webpage_url && (
                        <Typography>
                            Webpage: <Link target='_blank' href={props.analysis.webpage_url} variant='body2' className={classes.link}>
                                {t('containers.diagnocat.webpageLink')}
                            </Link>
                        </Typography>
                    )}
                    {props.analysis.analysis_type === 'STL' && props.analysis.stl_url && (
                        <Typography>
                            STL: <Link target='_blank' href={props.analysis.stl_url} variant='body2' className={classes.link}>
                                {t('containers.diagnocat.stlLink')}
                            </Link>
                        </Typography>
                    )}
                </Box>
                {
                    loggedInUser.roleId !== RADIOLOGIST_ROLE && (
                        <Box alignSelf='flex-end'>
                            <DeleteButton
                                acceptClicked={onDeleteAnalysisAccepted}
                                name={t('containers.diagnocat.deleteAnalysis')}
                            />
                        </Box>
                    )
                }
            </Box>
        </Paper>
    );
};

export default withErrorHandler(DiagnocatAnalysis);
