import React from 'react';
import { Button, Box } from '@material-ui/core';

const FormDialogButtons = (props) => {
    return (
        <Box margin={1} display='flex' justifyContent='flex-end'>
            {props.isDialog && (
                <Box margin={1}>
                    <Button onClick={props.closeButtonClicked} variant='text'>
                        Cancel
                    </Button>
                </Box>
            )}
            <Box margin={1}>
                <Button
                    onClick={props.submitButtonClicked}
                    variant='contained'
                    color='primary'
                    disabled={props.isSubmitDisabled}>
                    {props.submitTitle}
                </Button>
            </Box>
        </Box>
    );
};

export default FormDialogButtons;
