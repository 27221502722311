import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../../routing/routes/routes';
import withErrorHandler from '../../../hoc/withErrorHandler';
import { useTranslation } from 'react-i18next';

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const AppBreadcrumbs = () => {
    const { t } = useTranslation();

    return (
        <Route>
            {({ location }) => {
                const pathNames = location.pathname.split('/').filter((x) => x);
                const layout = pathNames.shift();
                return (
                    <Breadcrumbs aria-label='breadcrumb'>
                        {/*<LinkRouter color='inherit' to={'/' + layout}>*/}
                        {/*    {t('components.breadcrumbs.home')}*/}
                        {/*</LinkRouter>*/}
                        {pathNames.map((value, index) => {
                            const last = index === pathNames.length - 1;
                            const to = `/${pathNames.slice(0, index + 1).join('/')}`;

                            const route = routes.find((route) => route.path === to);
                            const name = route
                                ? routes.find((route) => route.path === to).name
                                : to.split('/')

                            return last ? (
                                <Typography variant={'h5'} color='textPrimary' key={to}>
                                    {t(name)}
                                </Typography>
                            ) : (
                                <LinkRouter color='inherit' to={'/' + layout + to} key={to}>
                                    {t(name)}
                                </LinkRouter>
                            );
                        })}
                    </Breadcrumbs>
                );
            }}
        </Route>
    );
};

export default withErrorHandler(AppBreadcrumbs);
