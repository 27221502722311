//Diagnocat APIs:
export const DIAGNOCAT_URL = process.env.REACT_APP_API_URL;
// export const DIAGNOCAT_URL = 'https://diagnocat-server.ct-dent.co.uk/diagnocat';
// export const DIAGNOCAT_URL = 'https://diagno-shay-backend.herokuapp.com/diagnocat'
// export const DIAGNOCAT_URL = process.env.REACT_APP_API_URL;
// export const CTDENT_URL = 'https://api.v1.ct-dent.co.uk/api/';
// export const CTDENT_URL = 'https://sandbox.api.v1.ct-dent.co.uk/api/';
export const CTDENT_URL = process.env.REACT_APP_CTDENT_URL;
export const CTDENT_API_KEY = '7l6J7u9MeJ91gV35MbbnR2FGCbh2Cof+CnWnYFyvVjg+pV1K5NWiu5FEvOpIV';
export const CTDENT_GET_ALL_APPOINTMENTS_BY_PARAMETERS = '/appointment/all';
//--new endpoints --//
export const DIAGNOCAT_PATIENTS = '/patients';
export const CTDENT_AUTH = '/auth/login';
export const DIAGNOCAT_GET_APPOINTMENT = 'appointments';
export const DIAGNOCAT_USERS = 'users';
export const CTDENT_DIAGNOCAT_SAVE_LINK = 'appointments/savelink';

