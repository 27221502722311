import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRouteByLayoutAndPath } from '../../../utils/routing.utils';
import withErrorHandler from '../../../hoc/withErrorHandler';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MOBILE_QUERY } from '../../../utils/constants';

const MenuItems = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);

    return (
        <List>
            {props.routes.map((route) => (
                <ListItem
                    button
                    //close the drawer after menu item selected, only on mobile
                    onClick={ isTabletOrMobile? props.menuItemClicked : null}
                    selected={route.layout + route.path === location.pathname}
                    key={route.name}
                    component={Link}
                    to={getRouteByLayoutAndPath(route.layout, route.path)}>
                    {!isTabletOrMobile && <ListItemIcon>{<route.icon />}</ListItemIcon>}
                    <ListItemText disableTypography primary={t(route.name)} />
                </ListItem>
            ))}

            {/*we are passing only resource routes to menu collapsable*/}
            {/*<MenuCollapsableItems routes={props.routes.filter((route) => route.isResource && route.isOnMenu)} />*/}
        </List>
    );
};

export default withErrorHandler(MenuItems);
