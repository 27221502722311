import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatient, selectPatients } from '../../../store/diagnocat/diagnocatSlice';
import { getFullName } from '../../../utils/globals.utils';
import { InputLabel, Radio } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { useStyles } from './UploadFileForm.style';
import { uploadFile } from '../../../store/diagnocat/uploadSlice';
import { STUDY_TYPES } from '../../../assets/conf/diagnocat.configuration';

const UploadFilesForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const patient = useSelector(selectPatient);
    const patients = useSelector(selectPatients);

    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedPatient, setSelectedPatient] = React.useState(null);
    const [isStatePatient, setStatePatient] = React.useState(false);
    const [selectedFileType, setSelectedFileType] = React.useState('CBCT');

    useEffect(() => {
        if (patient) {
            setSelectedPatient(patient);
            setStatePatient(true);
        }
    }, [patient]);

    const selectFile = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onSelectedPatientChanged = (event) => {
        setSelectedPatient(event.target.value);
    };

    const handleFileTypeChanged = (event) => {
        setSelectedFileType(event.target.value);
    };

    async function upload() {
        alert(t('containers.diagnocat.uploads.uploadStarted'))
        props.closeDialog();
        dispatch(uploadFile(selectedPatient, selectedFileType, selectedFile));
    }

    let patientSelection = isStatePatient ? null : (
        <Box>
            <FormControl className={classes.formControl}>
                <InputLabel>Select Patient</InputLabel>
                <Select
                    labelId='select-patient-label'
                    id='select-patient'
                    value={selectedPatient}
                    onChange={onSelectedPatientChanged}
                    defaultValue=''>
                    {[...patients]
                        .sort((a, b) => a.firstName.localeCompare(b.firstName))
                        .map((patient) => (
                            <MenuItem button key={patient.id} value={patient}>
                                {getFullName(patient.firstName, patient.lastName)}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );

    return (
        <div className='mg20'>
            <div>
                {STUDY_TYPES.map((option) => (
                    <>
                        <Radio
                            checked={selectedFileType === option.value}
                            onChange={handleFileTypeChanged}
                            value={option.value}
                            name='radio-button-demo'
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        <InputLabel style={{display: 'inline'}}>{option.label}</InputLabel>
                    </>
                ))}
            </div>

            {patients && patientSelection}
            <label htmlFor='btn-upload'>
                <input
                    id='btn-upload'
                    name='btn-upload'
                    style={{ display: 'none' }}
                    type='file'
                    onChange={selectFile}
                />
                <Button className='btn-choose' variant='outlined' component='span'>
                    {t('containers.diagnocat.uploads.chooseFiles')}
                </Button>
            </label>
            <div className='file-name'>
                {selectedFile && selectedFile.length > 0 ? selectedFile[0].name : null}
            </div>
            <Box mt={2}>
                <Button
                    className='btn-upload'
                    color='primary'
                    variant='contained'
                    component='span'
                    disabled={!selectedFile || !selectedPatient}
                    onClick={upload}>
                    {t('containers.diagnocat.uploads.uploadFile')}
                </Button>
            </Box>
        </div>
    );
};

export default UploadFilesForm;
