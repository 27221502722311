import React from 'react';
import { useFooterStyles } from './AppFooter.style';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MOBILE_QUERY } from '../../../utils/constants';

const AppFooter = (props) => {
    const classes = useFooterStyles();
    const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);

    return (
        <Box
            component='footer'
            className={ classes.appFooter }
            bgcolor='text.secondary'>
            <p>
                Disclaimer: Printed Reports, SimPlant conversions,3D reconstruction services, custom
                3D reconstruction services, panoramic reconstructions, nerve segmentation, data
                networking and any other services provided by CT Dent delivered "as is" without
                warranty, express or implied, statutory or otherwise, as to any matter whatsoever,
                including without limitation, merchantability and fitness for a particular purpose.
                CT Dent, Makes no warranty as to the sufficiency or suitability of said services and
                does not warrant the accuracy, completeness or correctness of the services. The
                results must be verified by a medical doctor to ensure accuracy before being used
                and compared to the medical images provided to the company. In no event will CT
                Dent, be liable to anyone for action taken in connection with the use of said
                services. CT Dent do not report on CBCT scans or x-rays as a matter of standard
                procedure. The Prescriber accepts responsibility for making arrangements for all
                radiographs and CBCT scans to be interpreted and reported on. CT Dent can arrange
                for scans and x-rays to be reported on by a consultant radiologist. In accepting
                responsibility to report on scans and xrays, the prescriber is also accepting the
                responsibility to ensure that they are competent and adequately trained to do so,
                and that their training remain current
            </p>
        </Box>
    );
};

export default AppFooter;
