//CSS constants
export const DRAWER_WIDTH = 270;

export const USER_MENU_ID = 'user-menu';
export const HELP_MENU_ID = 'help-menu';

export const MOBILE_MENU_ID = 'primary-search-account-menu-mobile';

//Routing constants
//TODO:
export const DASHBOARD_HOME = '/dashboard/appointments-and-scans';
export const DIAGNOCAT_DASHBOARD_HOME = '/dashboard/diagnocat';
export const AUTH_HOME = '/auth/sign-in';

//Link constants
//TODO - upload to new server
export const CBCT_PATHOLOGY_PDF_LINK = 'https://ct-dent.co.uk/images/books/cbct_pathology.pdf';


//TODO: i18n
export const DATE_FORMAT ='dd/MM/yyyy';
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm'
export const DATE_WEEK_FORMAT ='EEE dd/MM';

export const APPOINTMENTS_TABLE_PAGE_SIZE = 10;

export const TABLET_MOBILE_QUERY = { query: '(max-width: 1224px)' };
