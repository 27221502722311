import React, { useContext } from 'react';
import Menu from '@material-ui/core/Menu';
import { MenuItem, IconButton } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { AUTH_HOME, MOBILE_MENU_ID, USER_MENU_ID } from '../../../utils/constants';
import AlertDialog from '../dialog/AlertDialog';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AuthContext, DevContext } from '../../../App';
import { Link } from 'react-router-dom';
import { getRouteByLayoutAndPath } from '../../../utils/routing.utils';

//TODO: extract behaviour to "Navbar Menu" using child
const NavbarUserMenu = (props) => {
    const { t } = useTranslation();
    const isDevMode = useContext(DevContext);
    const history = useHistory();

    const isMenuOpen = Boolean(props.anchorEl);
    const isMobileMenuOpen = Boolean(props.mobileMoreAnchorEl);

    const [open, setOpen] = React.useState(false);

    //user logged out
    const handleAccept = () => {
        setOpen(false);
        // Redirect user to sign-in page
        history.push(AUTH_HOME);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        props.closeMenuClicked();
        setOpen(true);
    };

    // desktop view menu. will be rendered only in desktop mode
    const renderMenu = (
        <Menu
            anchorEl={props.anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={USER_MENU_ID}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={props.closeMenuClicked}>
            <MenuItem onClick={handleLogout}>{t('globals.logout')}</MenuItem>
        </Menu>
    );

    // mobile view menu. will be rendered only in mobile mode
    const renderMobileMenu = (
        <Menu
            anchorEl={props.mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={MOBILE_MENU_ID}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={props.closeMobileMenuClicked}>
            {/*{isDevMode && (*/}
            {/*    <div>*/}
            {/*        <MenuItem>*/}
            {/*            <IconButton aria-label='show 4 new mails' color='inherit'>*/}
            {/*                <Badge badgeContent={4} color='secondary'>*/}
            {/*                    <MailIcon />*/}
            {/*                </Badge>*/}
            {/*            </IconButton>*/}
            {/*            <p>{t('globals.messages')}</p>*/}
            {/*        </MenuItem>*/}
            {/*        <MenuItem>*/}
            {/*            <IconButton aria-label='show 11 new notifications' color='inherit'>*/}
            {/*                <Badge badgeContent={11} color='secondary'>*/}
            {/*                    <NotificationsIcon />*/}
            {/*                </Badge>*/}
            {/*            </IconButton>*/}
            {/*            <p>{t('globals.notifications')}</p>*/}
            {/*        </MenuItem>*/}
            {/*    </div>*/}
            {/*)}*/}
            <MenuItem onClick={props.openProfileMenuClicked}>
                <IconButton
                    aria-label='account of current user'
                    aria-controls='primary-search-account-menu'
                    aria-haspopup='true'
                    color='inherit'>
                    <AccountCircle />
                </IconButton>
                <p>{t('globals.profile')}</p>
            </MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            <AlertDialog
                open={open}
                cancelClicked={handleClose}
                acceptClicked={handleAccept}
                title={'globals.areYouSure'}
            />
            {renderMobileMenu}
            {renderMenu}
        </React.Fragment>
    );
};

export default NavbarUserMenu;
