import instance from '../axios/diagnocatAxios';

import {CTDENT_AUTH} from '../assets/conf/api-endpoints';

/**
 * validate username and password
 * @param {*} username 
 * @param {*} password 
 * @returns 
 */
export async function authUser(username, password) {
    const request = {
        username: username,
        password: password
    }
    const response = await instance.post(CTDENT_AUTH, request);
    return response;
}