import React, { Suspense, useContext, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { diagnocatRoutes } from './routes/routes';
import { getRouteElements } from '../utils/routing.utils';
import { DASHBOARD_HOME, DIAGNOCAT_DASHBOARD_HOME } from '../utils/constants';
import SuspenseDelayedFallback from '../components/SuspenseDelayedFallback';
import { Route } from 'react-router';
import DiagnocatPatient from '../containers/diagnocat/patient/DiagnocatPatient';
import { DiagnocatContext } from '../App';

const DashboardRouter = () => {
    const isDiagnocatMode = useContext(DiagnocatContext);

    let activeRoutes = getRouteElements(diagnocatRoutes)
        
    let dashboardHome = isDiagnocatMode ? DIAGNOCAT_DASHBOARD_HOME : DASHBOARD_HOME;


    useEffect(() => {
        //Dispatch fetch data only for doctors:
        if (!isDiagnocatMode) {
            //TODO - for better performance, these should be fetched from cache
        }
    }, []);

    return (
        <Switch>
            <Route path='/dashboard/diagnocat/:patientId' children={<DiagnocatPatient />} />
            <Suspense fallback={<SuspenseDelayedFallback />}>
                {activeRoutes}
                <Redirect from='*' to={dashboardHome} />
            </Suspense>
        </Switch>
    );
};

export default DashboardRouter;
