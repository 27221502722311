import React, { useEffect, useState } from 'react';
import withErrorHandler from '../hoc/withErrorHandler';

const SuspenseDelayedFallback = () => {
    const [show, setShow] = useState(false);

    // Add a minimum timeout of 300ms in order to show the loading fallback.
    // If the loading of the component is less than 300ms, we won't show loading fallback.
    useEffect(() => {
        let timeout = setTimeout(() => setShow(true), 300);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return <>{show && <div>Loading ...</div>}</>;
};

export default withErrorHandler(SuspenseDelayedFallback);
