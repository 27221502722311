import React from 'react';
import { Box } from '@material-ui/core';
import { Redirect, Switch } from 'react-router-dom';
import authRoutes from '../../routing/routes/authRoutes';
import { getRouteElements } from '../../utils/routing.utils';
import { AUTH_HOME } from '../../utils/constants';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/images/ctdent_logo.png';
import { useAuthStyles } from '../../containers/auth/auth.style';
import withErrorHandler from '../../hoc/withErrorHandler';
import { useTranslation } from 'react-i18next';

const AuthLayout = () => {
    const classes = useAuthStyles();
    const { t } = useTranslation();

    return (
        <Container component='main' maxWidth='false' disableGutters={true} >
            <Box className={classes.authLayout}>
                <Box bgcolor='black' padding={5}>
                    <Box className={classes.banner}>
                        <Box>
                            <img src={logo} alt={t('auth.bannerHeading1')} />
                        </Box>
                        
                        <Box className={classes.bannerBackground} >
                        </Box>
                        <Box>
                                <Typography variant='h2'>{t('auth.bannerHeading2')}</Typography>
                                <Typography variant="h3">{t('auth.bannerHeading3')}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.content} padding={5}>
                    <Switch>
                        {getRouteElements(authRoutes)}
                        <Redirect from='*' to={AUTH_HOME} />
                    </Switch>
                </Box>
            </Box>
            {/* <Box mt={5}>
                <Copyright />
            </Box> */}
        </Container>
    );
};

export default withErrorHandler(AuthLayout);
