import React from 'react';
import { Field } from 'formik';
import { Checkbox, CheckboxWithLabel } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import withErrorHandler from '../../../../../hoc/withErrorHandler';
import { getCheckedTeethIcon, getUncheckedTeethIcon } from './teethIcons.utils';

const useStyles = makeStyles((theme) => ({
    checkboxLabel: {
        margin: '0.5em',
    },
}));

const JawTeethCheckboxes = (props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {props.teethValues.map((opt) => (
                <Field
                    // checkedIcon={getCheckedTeethIcon(opt.value)}
                    // icon={getUncheckedTeethIcon(opt.value)}
                    style={{
                        width: 36,
                        height: 36,
                    }}
                    type='checkbox'
                    component={CheckboxWithLabel}
                    // component={Checkbox}
                    name={props.teethListName} //teethListName will be 'teethList' for regular form and 'subform.teethList' for nested forms
                    key={opt.value}
                    value={opt.value}
                    color={'primary'}
                    // checked={props.checked || props.teethList.includes(opt.value)} //teethList will be teethList for regular form and subform.teethList for nested forms
                    onChange={props.teethChanged}
                    disabled={
                        props.disableTeeth
                            ? !props.availableTeeth.includes(opt.value)
                            : props.disableTeeth
                    }
                    Label={{
                        label: opt.label,
                        labelPlacement: props.labelPlacement,
                        className: classes.checkboxLabel,
                    }}
                />
            ))}
        </React.Fragment>
    );
};

export default withErrorHandler(JawTeethCheckboxes);
