//hiddenDays: [0] means hide Sundays
export const branches = [
    {
        id: 1,
        value: 'london',
        name: 'London',
        address: 'Mock London Address',
        dayStart: '08:00:00',
        dayEnd: '20:00:00',
        slotDuration: '00:05:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_London.pdf'
    },
    {
        id: 19,
        value: 'manchester',
        name: 'Manchester',
        address: 'Mock Manchester Address',
        dayStart: '09:00:00',
        dayEnd: '17:00:00',
        slotDuration: '00:15:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_Manchester.pdf'
    },
    {
        id: 20,
        value: 'birmingham',
        name: 'Birmingham',
        address: 'Mock Birmingham Address',
        dayStart: '09:00:00',
        dayEnd: '17:00:00',
        slotDuration: '00:15:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_Birmingham.pdf'
    },
    {
        id: 30,
        value: 'leeds',
        name: 'Leeds',
        address: 'Mock Leeds Address',
        dayStart: '09:00:00',
        dayEnd: '17:00:00',
        slotDuration: '00:15:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_Leeds.pdf'
    },
    {
        id: 31,
        value: 'bristol',
        name: 'Bristol',
        address: 'Mock Bristol Address',
        dayStart: '09:00:00',
        dayEnd: '17:00:00',
        slotDuration: '00:15:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_Bristol.pdf'
    },
    {
        id: 32,
        value: 'nottingham',
        name: 'Nottingham',
        address: 'Mock Nottingham Address',
        dayStart: '09:00:00',
        dayEnd: '17:00:00',
        slotDuration: '00:15:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_Nottingham.pdf'
    },
    {
        id: 35,
        value: 'colchester',
        name: 'Colchester',
        address: 'Mock Colchester Address',
        dayStart: '09:00:00',
        dayEnd: '17:00:00',
        slotDuration: '00:15:00',
        hiddenDays: [0],
        referralLink: 'https://ct-dent.co.uk/downloads/files/ReferralWebsite_Colchester.pdf'
    },
];

export const locales = [
    {
        name: 'en',
        value: 'en',
    },
    {
        name: 'it',
        value: 'it',
    }
]

export const PENDING = 'pending';
export const IDLE = 'idle';
export const AUTH_DATA_STORAGE = "diagnocat-auth-data";