import { makeStyles } from '@material-ui/core/styles';
import Banner from '../../assets/images/user-login-bg.jpeg'
export const useAuthStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '8px'
    },
    banner: {
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0rem',
        textAlign: 'center',
    },
    bannerBackground: {
        flexGrow: '1',
        width: '100%',
        backgroundImage: `url(${Banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: ' auto 86%',
    },

    authLayout: {
        display: 'flex',
        height: '100vh',

        '& > .MuiBox-root': {

            '&:first-child':{
                display: 'none'
            },

            width: '100%',
            [theme.breakpoints.up('sm')]: {
                '&:first-child':{
                    display: 'block'
                },
                width: '50%'
            }
        },
        '& h2': {
            fontSize:   60,
            fontWeight: '400'
        },
        '& h3': {
            fontSize: 60,
            fontWeight: '900'
        }
    },
    content: {
        flexGrow: 1,
        display:"flex",
        justifyContent: "center",
        alignItems:"center",
    }
}));

