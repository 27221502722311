import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearSelectedPatient,
    sendLinkToDoctor,
    selectLoading,
    selectPatients,
    selectStudies,
    loadAppointmentByPatientId,
    selectAppointment,
    deleteAnalysisState,
    selectStudiesLoading,
} from '../../../store/diagnocat/diagnocatSlice';
import { getFullName } from '../../../utils/globals.utils';
import DiagnocatStudy from './DiagnocatStudy';
import Typography from '@material-ui/core/Typography';
import AppSpinner from '../../../components/UI/spinner/AppSpinner';
import { DIAGNOCAT_DASHBOARD_HOME } from '../../../utils/constants';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import UploadFilesButton from '../dialogs/UploadFiles';
import Divider from '@material-ui/core/Divider';
import {
    deletePatientState,
    deleteStudyState,
    loadPatientStudiesWithImagesAndAnalyses,
} from '../../../store/diagnocat/diagnocatSlice';
import DiagnocatActiveSessions from '../DiagnocatActiveSessions';
import SendLinkToDoctor from './SendLinkToDoctor';
import DiagnocatStatusCheckboxes from './DiagnocatStatusCheckboxes';
import DeleteButton from './DeleteButton';
import { selectLoggedInUser } from '../../../store/globalSlice';
import { IDLE } from '../../../assets/conf/configurations';
import { RADIOLOGIST_ROLE } from '../../../assets/conf/diagnocat.configuration';
import { addLoading, removeLoading } from '../../../store/uiSlice';
import { useCtDentStyle } from '../ct-dent.style';

const DiagnocatPatient = () => {
    const { t } = useTranslation();
    const { patientId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useCtDentStyle();

    const patient = useSelector(selectPatients).find((patient) => patient.patientId === patientId);
    const isLoading = useSelector(selectLoading);
    const isStudiesLoading = useSelector(selectStudiesLoading);
    const studies = useSelector(selectStudies);
    const appointment = useSelector(selectAppointment);
    const loggedInUser = useSelector(selectLoggedInUser);

    // dispatch fetching of patient's studies
    useEffect(() => {
        if (patient) {
            //TODO - rename patient.id to patient.diagnocatId or patient.uid
            dispatch(loadPatientStudiesWithImagesAndAnalyses(patient.id));
            dispatch(loadAppointmentByPatientId(patient.patientId));
            return function cleanup() {
                dispatch(clearSelectedPatient());
            };
        }
    }, [patient]);

    useEffect(()=>{
        if (isLoading !== IDLE){
            dispatch(addLoading("diagnocat-patient"))

        }else{
            dispatch(removeLoading('diagnocat-patient'))
        }
    }, [isLoading])

    useEffect(()=>{
        if (isStudiesLoading !== IDLE){
            dispatch(addLoading("diagnocat-analysis"))

        }else{
            dispatch(removeLoading('diagnocat-analysis'))
        }
    }, [isStudiesLoading])

    const onDeletePatient = () => {
        dispatch(deletePatientState(patient.id)).then(() => {
            alert(t('containers.diagnocat.patientSuccessfullyDeleted'));
            history.push(DIAGNOCAT_DASHBOARD_HOME);
        });
    };

    const onDeleteStudy = (studyUID) => {
        dispatch(deleteStudyState(studyUID)).then(() => {
            alert(t('containers.diagnocat.studySuccessfullyDeleted'));
        });
    };

    const onDeleteAnalysis = (studyUID, analysisUID) => {
        dispatch(deleteAnalysisState(studyUID, analysisUID)).then(() => {
            alert(t('containers.diagnocat.analysisSuccessfullyDeleted'));
        });
    };

    const onSendLinkToDoctors = (doctorEmail) => {
        let appointmentId = appointment.id;
        dispatch(sendLinkToDoctor(patient.id, doctorEmail, appointmentId)).then(() => {
            alert(t('containers.diagnocat.doctorsLinkSuccessfullySent'));
        });
    };

    let studiesContent =
        studies && studies.length > 0 ? (
            studies.map((study) => (
                <React.Fragment key={study.uid}>
                    <DiagnocatStudy
                        study={study}
                        deleteStudy={onDeleteStudy}
                        deleteAnalysis={onDeleteAnalysis}
                    />
                    <Divider />
                </React.Fragment>
            ))
        ) : (
            <div>{t('containers.diagnocat.noStudies')}</div>
        );

    return (
        <div>
            <Typography variant="h2" className={classes.pageHeader}>{t('appTitle')}</Typography>
            <Paper style={{ textAlign: 'left', padding: 10 }}>
                <Box>
                    <Typography variant={'h5'}>
                        {patient && getFullName(patient.firstName, patient.lastName)}
                    </Typography>
                    <Typography>
                        <b>{t('globals.gender')}:</b> {patient && patient.gender}
                    </Typography>
                    <Typography>
                        <b>{t('globals.dateOfBirth')}:</b> {patient && patient.dateOfBirth}
                    </Typography>

                    {/*TODO - extract (appointment details)*/}
                    {isLoading !== IDLE ? (
                        null
                    ) : (
                        <>
                            <Typography>
                                <b>{t('globals.appointment')}: </b>
                                {appointment ? appointment.id : t('globals.notFound')}
                            </Typography>
                            <Typography>
                                <b>{t('globals.radiologistMessage')}: </b>
                                {appointment
                                    ? appointment.radiologist_message
                                    : t('globals.notFound')}
                            </Typography>

                            {loggedInUser.roleId !== RADIOLOGIST_ROLE && (
                                <Typography
                                    color={
                                        appointment && appointment.diagnocat_link
                                            ? 'default'
                                            : 'error'
                                    }>
                                    {appointment && appointment.diagnocat_link
                                        ? t('containers.diagnocat.linkSentToDoctor')
                                        : t('containers.diagnocat.linkNotSentToDoctor')}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
                {appointment && <DiagnocatStatusCheckboxes selectedAppointment={appointment} />}
                {loggedInUser.roleId !== RADIOLOGIST_ROLE && (
                    <Box display='flex' mt={1}>
                        <Box display='flex' flexGrow={1} style={{gap: 12}}>
                            <UploadFilesButton />
                            <Box>
                                <SendLinkToDoctor sendLinkToDoctor={onSendLinkToDoctors} />
                            </Box>
                        </Box>
                        <Box>
                            <DeleteButton
                                acceptClicked={onDeletePatient}
                                name={t('containers.diagnocat.deletePatient')}
                            />
                        </Box>
                    </Box>
                )}
            </Paper>

            {isStudiesLoading !== 'idle' ? null : studiesContent}
            {loggedInUser.roleId !== RADIOLOGIST_ROLE && (
                <Box mt={2}>
                    <DiagnocatActiveSessions />
                </Box>
            )}
        </div>
    );
};

export default DiagnocatPatient;
