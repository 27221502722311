import { fade, makeStyles } from '@material-ui/core/styles';

export const useCtDentStyle = makeStyles(
    (theme) => ({
        paper: {
            padding: 20,

            '& h2': {
                fontSize: 20,
                display: 'flex',
                alignItems: 'center',
                marginBottom: 12,
            }
        },
        actions: {
            display: 'flex',
            gap: 12,
        },
        searchDiv: {
            display: 'flex',
        },
        search: {
        },
        searchIcon: {
        },
        inputRoot: {
            color: 'inherit',
            border: '1px solid',
            borderRadius: theme.shape.borderRadius,
            borderColor: fade(theme.palette.common.black, 0.25),
        },
        inputInput: {
            padding: theme.spacing(1),
            transition: theme.transitions.create('width'),
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        pageHeader: {
            fontSize: 28,
            marginBottom: 24,
            fontWeight: 400
        },
        link: {
            color: 'blue'
        }
        
    }),
    { index: 1 }
);
