import React from 'react';
import { Box, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import FormDialogButtons from '../../../../components/UI/dialog/FormDialogButtons';
import { makeStyles } from '@material-ui/core/styles';
import { sliceStep, sliceThickness } from '../../../../utils/forms/options/diagnocatReport.options';
import TeethSelection from './teethSelection/TeethSelection';
import { EndodonticsImplantologyValidationSchema } from '../../../../utils/forms/validators/schemaValidators.yup';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    field: {
        minWidth: '30%',
        margin: '1em',
    },
}));

const formInitialValues = {
    sliceThickness: '',
    sliceSteps: '',
    teethList: [],
    upperLeft: false,
    upperRight: false,
    lowerLeft: false,
    lowerRight: false,
    allTeeth: false,
};

const EndodonticsImplantologyReportForm = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const onSubmitForm = (values, { setSubmitting }) => {
        setTimeout(() => {
            setSubmitting(false);

            let analysisRequest = {
                analysis_type: props.type,
                analysis_params: {
                    roi: {
                        teeth: values.teethList.map((teeth) => parseInt(teeth)),
                    },
                    step_mm: parseFloat(values.sliceSteps),
                    thickness_mm: parseFloat(values.sliceThickness),
                },
            };
            props.formSubmitted(analysisRequest);
        }, 500);
    };

    return (
        <Formik
            initialValues={formInitialValues}
            validationSchema={EndodonticsImplantologyValidationSchema}
            onSubmit={onSubmitForm}>
            {({
                submitForm,
                isSubmitting,
                isValid,
                dirty,
                values,
                setFieldValue,
                handleChange,
            }) => (
                <Form>
                    <Box margin={1} padding={1}>
                        <Field
                            className={classes.field}
                            component={TextField}
                            type='text'
                            name='sliceThickness'
                            label='Slice Thickness'
                            select
                            variant='standard'
                            margin='normal'
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}>
                            {sliceThickness.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Field>
                        <Field
                            className={classes.field}
                            component={TextField}
                            type='text'
                            name='sliceSteps'
                            label='Slice Steps'
                            select
                            variant='standard'
                            margin='normal'
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}>
                            {sliceStep.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Field>
                    </Box>
                    <TeethSelection
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        teethList = {values.teethList}

                        isCheckboxes
                        teethChanged={(value) => setFieldValue('teethList', value)}
                    />

                    <FormDialogButtons
                        submitButtonClicked={submitForm}
                        isSubmitting={isSubmitting}
                        isSubmitDisabled={!(isValid && dirty)}
                        closeButtonClicked={props.handleClose}
                        submitTitle={t('globals.submit')}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default EndodonticsImplantologyReportForm;
