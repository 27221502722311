import React, { Suspense, useEffect } from 'react';
import './App.scss';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import AuthLayout from './layouts/auth/AuthLayout';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from './assets/styles/theme';
import { selectAuth, selectAuthToken, selectIsAuthenticated } from './store/globalSlice';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from './components/UI/LoadingSpinner/loading-spinner';
import { selectIsLoading } from './store/uiSlice';
import useAuth from './hooks/useAuth';
import store from './store/store';
import diagnocatInstance from './axios/diagnocatAxios';
import axios from 'axios';
import { config } from 'dotenv';
//Dev mode context - used to determine whether to show or hide components in development.
export const DevContext = React.createContext(false);

//Diagnocat mode context - used to determine whether the app is used as diagnocat standalone (development).
export const DiagnocatContext = React.createContext(true);

//Authentication context - used to determine whether a user is authenticated.
export const AuthContext = React.createContext({
    isAuthenticated: false,
    setAuthenticated: () => {},
});



const App = () => {
    const history = useHistory();
    const auth = useAuth();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isLoading = useSelector(selectIsLoading);
      
    useEffect(()=>{
        auth.reloadAuthData();

        diagnocatInstance.interceptors.request.use(
            config =>{
                const token =  store.getState().global.token;
                if (token){
                    config.headers.Authorization = `Bearer ${token}`
                }

                return config;
            }
        );

    }, []);

    useEffect(()=>{
        if (!!isAuthenticated){
            history.push('/dashboard/diagnocat');
        }
    }, [isAuthenticated])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {/*Set to true for doctor website, false for diagnocat*/}
            <DevContext.Provider value={false}>
                {/*Set to false for doctor website, true for diagnocat*/}
                <DiagnocatContext.Provider value={true}>
                    <AuthContext.Provider value={{ isAuthenticated }}>
                        {/*adding suspense to wait until i18n loaded*/}
                        <Suspense fallback='loading'>
                            <LoadingSpinner open={isLoading}/>

                            <div className='App'>
                                <Switch>
                                    {isAuthenticated && (
                                        <Route
                                            path='/dashboard'
                                            render={(props) => <DashboardLayout {...props} />}
                                        />
                                    )}
                                    <Route
                                        path='/auth'
                                        render={(props) => <AuthLayout {...props} />}
                                    />
                                    <Redirect from='/' to='/auth/sign-in' />
                                </Switch>
                            </div>

                        </Suspense>
                    </AuthContext.Provider>
                </DiagnocatContext.Provider>
            </DevContext.Provider>
        </ThemeProvider>
        
    );
};

export default withRouter(App);
