import { Route } from 'react-router-dom';
import React from 'react';

/**
 *
 * @param routes
 * @returns <Route> element which redirect to layout+path (for example: /dashboard/team-dashboard)
 */
export const getRouteElements = (routes) => {
    return routes.map((prop, key) => {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
    });
};

export const getRouteByLayoutAndPath = (layout, path) => {
    return layout + path;
}
