import instance from '../../axios/diagnocatAxios';
import UploadService from './upload-files.service';
import { timer } from '../../utils/globals.utils';

export async function fetchPatientStudiesWithImagesAndAnalyses(patientId) {
    const studies = await fetchPatientStudies(patientId);
    let studiesWithImagesAndAnalyses = [];

    for (let study of studies) {
        let newStudy = { ...study }; 

        // fetch study's analyses and set as study's analyses
        newStudy.analyses = await fetchStudyAnalyses(study.uid);

        studiesWithImagesAndAnalyses.push(newStudy);
    }

    return studiesWithImagesAndAnalyses;
}

/**
 *  get list of studies by patient id
 * @param {*} patientId - specifies the id of the patient associated to the study
 * @returns list of studies
 */
export async function fetchPatientStudies(patientId) {
    const response = await instance.get(`/diagnocat/patients/${patientId}/studies`);
    return response.data;
}

/**
 * Create a new study for the patient with given id
 */
export async function createStudy(patientId, studyRequest) {
    const response = await instance.post(`/diagnocat/patients/${patientId}/createStudy`, studyRequest);

    return response.data;
}

/**
 *  get images associated to study
 * @param {*} studyId 
 */
export async function fetchStudyImages(studyId) {
    const response = await instance.get(`/diagnocat/studies/${studyId}/images`);
    return response.data;
}

/**
 * get analyses associated to study
 * @param {*} studyId 
 */
export async function fetchStudyAnalyses(studyId) {
    const response = await instance.get(`/diagnocat/studies/${studyId}/analyses`);
    return response.data;
}

/**
 * delete patient data
 * @param {*} patientUID - id of patient to be removed
 */
export async function deletePatient(patientUID) {
    const response = await instance.delete(`/diagnocat/patients/${patientUID}`);
    return response.data;
}

/**
 * delete study record
 * @param {*} studyUID 
 */
export async function deleteStudy(studyUID) {
    const response = await instance.delete(`/diagnocat/studies/${studyUID}`);
    return response.data;
}

/**
 * delete analysis record
 * @param {*} analysisUID 
 */
export async function deleteAnalysis(analysisUID) {
    const response = await instance.delete(`/diagnocat/analyses/${analysisUID}`);
    return response.data;
}

/**
 * generate doctors link based from patient id and doctors email
 * @param {*} patientUID 
 * @param {*} doctorEmail 
 */
export async function fetchDoctorsLink(patientUID, doctorEmail) {
    let req = {
        email: doctorEmail,
        send_email: false,
        email_lang: 'en-gb',
    };
    const response = await instance.post(`/diagnocat/patients/${patientUID}/shares`, req);
    return response.data;
}

/**
 *
 * @param studyId
 * @param analysisParams — required (optional for 'gp', 'gp_2d'), params of the analysis to be requested
 * {
 *  "roi": {
 *      "teeth": [18, 17],
 *      "anatomy": ["mandible", "maxilla", "sinus", "canal"]
 *   },
 *   "step_mm": 1.0,
 *   "thickness_mm": 0.5,
 *   "merge_stl": false
 *   }
 * @param analysisType — type of the analysis to be requested, supported analysis types:
 *                      'gp_2d' for FMX and PANORAMA study types
 *                      'gp', 'implant', 'endo', 'molar', 'stl' for CBCT study type
 * @returns {Promise<any>}
 */
export async function requestStudyAnalysis(studyId, analysisRequest) {
    const response = await instance.post(`/diagnocat/studies/${studyId}/analyses`, analysisRequest);
    return response.data;
}

export async function getAnalysisById(analysisId) {
    const response = await instance.get(`/diagnocat/analyses/${analysisId}`);
    return response.data;
}

/**
 * Send get analysis request every 1 second, until the response shows analysis is complete.
 */
export async function getCompletedAnalysis(analysisId) {
    let complete = false;
    let error = null;
    let analysisResult;

    // loop until analysis is complete
    while (complete !== true && !error) {
        analysisResult = await getAnalysisById(analysisId);
        complete = analysisResult.complete;
        error = analysisResult.error;
        await timer(1000);
    }

    if (error) {
        throw 'Error with analysis: ' + error;
    }

    return analysisResult;
}

/**
 * Check session info every 1 second, until the session is closed.
 */
export async function getClosedSessionInfo(sessionIdRequest) {
    let status = '';
    let error = null;
    let fileError = null;
    let checkSessionInfoResult;

    // loop until session is closed
    while (status !== 'closed' && !error && !fileError) {
        checkSessionInfoResult = await UploadService.checkSessionInfo(sessionIdRequest);
        status = checkSessionInfoResult.data.session_info.status;
        error = checkSessionInfoResult.data.session_info.error;
        fileError = checkSessionInfoResult.data.session_info.summary.file_errors;
        await timer(1000);
    }

    if (error) {
        throw 'Error with getting session info: ' + error;
    }


    return checkSessionInfoResult;
}

/**
 * get patients by name
 * @param {string} name - name of the patient to search
 * @returns list of users with matching name
 */
export async function fetchPatientsByName(name) {
    const ctdent_patients_response = await instance.get(`/patients?offset=${0}&limit=${10}&fullName=${name}&orderBy=created_at&orderDirection=DESC`);
    let patients = ctdent_patients_response.data.data.map((patient) => ({
        id: patient.diagnocat_uid,
        patientId: patient.id.toString(),
        lastName: patient.last_name,
        firstName: patient.first_name,
        dateOfBirth: patient.dob,
        gender: patient.gender,
        appointmentStatus: patient.diagnocat_status,
    }));
    return patients;
}

/**
 * get patients
 * @param {*} offset - specifies the row which to start retrieving data
 * @param {*} limit - maximum number of rows to return
 * @param {*} fullName - if specifies, will filter to return only matching patient name.
 * @returns list of patients
 */
export async function fetchPatients(offset, limit, fullName) {
    const ctdent_patients_response = await instance.get(`/patients?offset=${offset}&limit=${limit}&fullName=${fullName}&orderBy=created_at&orderDirection=DESC`);
    let patients = ctdent_patients_response.data.data.map((patient)=>{
        return patient;

    }).map((patient) => ({
        id: patient.diagnocat_uid,
        patientId: patient.id.toString(),
        lastName: patient.last_name,
        firstName: patient.first_name,
        dateOfBirth: patient.dob,
        gender: patient.gender,
        appointmentStatus: patient["appointments.diagnocat_status"],
    }));
    return patients; 
}

/**
 * create new patient data
 * @param {*} data - patient data to create 
 * @returns new patient data
 */
export const createPatient = async (data) =>{
    const result = await instance.post('/patients', data);
    return result
}

/**
 *  convert study type to analysis type
 * @param {*} studyType - study type to evaludate
 * @returns 
 */
export const getAnalysisTypeAccordingToStudyType = (studyType) => {
    return studyType === 'CBCT' ? 'GP' : 'GP_2D';
};