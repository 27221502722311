import React from 'react';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import withErrorHandler from '../../../../../hoc/withErrorHandler';

const JawSideCheckbox = (props) => {
    return (
        <Field
            type='checkbox'
            component={CheckboxWithLabel}
            name={props.type}
            key={props.type}
            value={props.type}
            color={'primary'}
            onChange={props.checkboxChanged}
            checked={props.checked}
            Label={{
                label: props.label,
                labelPlacement: 'top',
            }}
        />
    );
};

export default withErrorHandler(JawSideCheckbox);
