export const REPORT_TYPES = {
    ENDODONTICS: {
        title: 'containers.diagnocat.reportsDialogs.endoTitle',
        content: 'containers.diagnocat.reportsDialogs.endoContent',
    },
    IMPLANTOLOGY: {
        title: 'containers.diagnocat.reportsDialogs.implantTitle',
        content: 'containers.diagnocat.reportsDialogs.implantContent',
    },
    THIRD_MOLAR: {
        title: 'containers.diagnocat.reportsDialogs.MolarTitle',
        content: 'containers.diagnocat.reportsDialogs.MolarContent',
    },
    STL: {
        title: 'containers.diagnocat.reportsDialogs.StlTitle',
        content: 'containers.diagnocat.reportsDialogs.StlContent',
    },
};

export const DIALOG_TYPES = {
    NEW_PATIENT: 'newPatientDialog',
    UPLOAD: 'uploadDialog',
    ORDER_REPORT: 'orderReportDialog',
    REPORT_DETAILS: 'reportDetailsDialog',
};

export const STUDY_TYPES = [
    {
        label: 'CBCT',
        value: 'CBCT',
    },
    {
        label: 'PANORAMIC',
        value: 'PANORAMA',
    },
];

export const CHECKED_BY_PROCESSING_TEAM = 1;
export const CHECKED_BY_RADIOLOGIST = 2;

export const RADIOLOGIST_ROLE = 30;

export const DIAGNOCAT_APPOINTMENT_STATUSES = [
    {
        label: 'Checked by processing team',
        value: CHECKED_BY_PROCESSING_TEAM,
    },
    {
        label: 'Checked by a radiologist',
        value: CHECKED_BY_RADIOLOGIST,
    },
];

export const ANALYSIS_TYPES = {
    MOLAR: 'MOLAR',
    STL: 'STL',
    ENDO: 'ENDO',
    IMPLANT: 'IMPLANT',
};
