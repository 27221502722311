import Logout from '../../containers/auth/logout/logout';
import SignIn from '../../containers/auth/sign-in/SignIn';

const authRoutes = [
    {
        path: '/sign-in',
        layout: '/auth',
        name: "auth.signIn",
        component: SignIn,
    },
    {
        path: '/logout',
        layout: '/auth',
        name:  'auth.logout',
        component: Logout,

    }
];

export default authRoutes;
