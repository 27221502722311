import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Toolbar, IconButton, Typography, Menu, Tooltip, Avatar, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useNavbarStyles } from './AppNavbar.style';
import NavbarUserMenu from './NavbarUserMenu';
import NavbarSections from './NavbarSections';
import { useSelector } from 'react-redux';
import { selectLoggedInUser, selectRole } from '../../../store/globalSlice';
import { DevContext } from '../../../App';
import NavbarHelpMenu from './NavbarHelpMenu';
import Box from '@material-ui/core/Box';
import logo from '../../../assets/images/ctdent_logo.png';
import NavbarSupport from './NavbarSupport';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MOBILE_QUERY } from '../../../utils/constants';
import withErrorHandler from '../../../hoc/withErrorHandler';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import UserProfile from './UserProfile';
import { Link } from 'react-router-dom';
const ADVANCED_SEARCH_DIALOG = 'advancedSearchDialog';

const AppNavbar = (props) => {
    const classes = useNavbarStyles();
    const isDevMode = useContext(DevContext);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const isTabletOrMobile = useMediaQuery(TABLET_MOBILE_QUERY);

    //TODO: will be moved
    const role = useSelector(selectRole);
    const loggedInUser = useSelector(selectLoggedInUser);

    //TODO: combine
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    //We are using 2 dialogs on this component, so we need to keep activeDialog state
    const [activeDialog, setActiveDialog] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleAdvancedSearch = () => {
        setActiveDialog(ADVANCED_SEARCH_DIALOG);
    };

    const handleDialogClose = () => {
        setActiveDialog(null);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleHelpMenuOpen = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    return (
        <div>
 
            <AppBar
                position='fixed'
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.open,
                })}
                >
                <Toolbar>
                    
                    <Box sx={{flexGrow: 1}}>
                        <Link to='/dashboard/diagnocat'>
                        <img
                            src={logo}
                            style={{ height: '64px' }}
                        />
                        </Link>'
                        {/*TODO: only dev mode*/}
                        {!isTabletOrMobile && (
                            <Typography className={classes.version}>
                                {process.env.REACT_APP_VERSION}
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        <UserProfile />
                    </Box>
                    
                    {/*{isDevMode && (*/}
                    {/*    <div className={classes.searchDiv}>*/}
                    {/*        <div className={classes.search}>*/}
                    {/*            <div className={classes.searchIcon}>*/}
                    {/*                <SearchIcon />*/}
                    {/*            </div>*/}
                    {/*            <InputBase*/}
                    {/*                placeholder='Search…'*/}
                    {/*                classes={{*/}
                    {/*                    root: classes.inputRoot,*/}
                    {/*                    input: classes.inputInput,*/}
                    {/*                }}*/}
                    {/*                inputProps={{ 'aria-label': 'search' }}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        <Button*/}
                    {/*            variant='contained'*/}
                    {/*            color='secondary'*/}
                    {/*            onClick={handleAdvancedSearch}>*/}
                    {/*            {t('globals.advancedSearch')}*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    {/* <div className={classes.grow} /> */}
                    {/* <NavbarSupport /> */}

                    {/*{isDevMode && (*/}
                    {/*    <AppSelect*/}
                    {/*        items={locales}*/}
                    {/*        name='Locale'*/}
                    {/*        selectedValueChanged={handleLocaleChange}*/}
                    {/*        defaultSelected={locales[0]}*/}
                    {/*    />*/}
                    {/*)}*/}

                    {/* {isDevMode && (
                        <NavbarSections
                            profileMenuClicked={handleProfileMenuOpen}
                            helpMenuClicked={handleHelpMenuOpen}
                            // TODO: handle mobile help+user menus
                            mobileMenuClicked={handleMobileMenuOpen}
                            loggedInUser={loggedInUser}
                        />
                    )} */}
                </Toolbar>
            </AppBar>
            {/*Second toolbar is used together with position-fixed in order to stick the Navbar to the top*/}
            <Toolbar />
            <NavbarUserMenu
                anchorEl={anchorEl}
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                openProfileMenuClicked={handleProfileMenuOpen}
                closeMobileMenuClicked={handleMobileMenuClose}
                closeMenuClicked={handleMenuClose}
            />

            <NavbarHelpMenu
                anchorEl={anchorEl2}
                mobileMoreAnchorEl={mobileMoreAnchorEl}
                openProfileMenuClicked={handleHelpMenuOpen}
                closeMobileMenuClicked={handleMobileMenuClose}
                closeMenuClicked={handleMenuClose}
            />
        </div>
    );
};

export default withErrorHandler(AppNavbar);
