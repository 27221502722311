import React, { useContext } from 'react';
import Menu from '@material-ui/core/Menu';
import { MenuItem, IconButton, Badge, Typography, Toolbar, ListItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { HELP_MENU_ID, MOBILE_MENU_ID } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { AuthContext, DevContext } from '../../../App';
import { Link } from 'react-router-dom';
import { getRouteByLayoutAndPath } from '../../../utils/routing.utils';

//TODO: extract behaviour to "Navbar Menu" using child
const NavbarHelpMenu = (props) => {
    const { t } = useTranslation();
    const isDevMode = useContext(DevContext);

    const isMenuOpen = Boolean(props.anchorEl);
    const isMobileMenuOpen = Boolean(props.mobileMoreAnchorEl);

    // desktop view menu. will be rendered only in desktop mode
    const renderMenu = (
        <Menu
            anchorEl={props.anchorEl}
            id={HELP_MENU_ID}
            keepMounted
            // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            // transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={isMenuOpen}
            onClose={props.closeMenuClicked}>
            
        </Menu>
    );

    // mobile view menu. will be rendered only in mobile mode
    const renderMobileMenu = (
        <Menu
            anchorEl={props.mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={MOBILE_MENU_ID}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={props.closeMobileMenuClicked}>
            {/*{isDevMode && (*/}
            {/*    <div>*/}
            {/*        <MenuItem>*/}
            {/*            <IconButton aria-label='show 4 new mails' color='inherit'>*/}
            {/*                <Badge badgeContent={4} color='secondary'>*/}
            {/*                    <MailIcon />*/}
            {/*                </Badge>*/}
            {/*            </IconButton>*/}
            {/*            <p>{t('globals.messages')}</p>*/}
            {/*        </MenuItem>*/}
            {/*        <MenuItem>*/}
            {/*            <IconButton aria-label='show 11 new notifications' color='inherit'>*/}
            {/*                <Badge badgeContent={11} color='secondary'>*/}
            {/*                    <NotificationsIcon />*/}
            {/*                </Badge>*/}
            {/*            </IconButton>*/}
            {/*            <p>{t('globals.notifications')}</p>*/}
            {/*        </MenuItem>*/}
            {/*    </div>*/}
            {/*)}*/}
            <MenuItem onClick={props.openProfileMenuClicked}>
                <IconButton
                    aria-label='account of current user'
                    aria-controls='primary-search-account-menu'
                    aria-haspopup='true'
                    color='inherit'>
                    <AccountCircle />
                </IconButton>
                <p>TEST HELP</p>
            </MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            {renderMobileMenu}
            {renderMenu}
        </React.Fragment>
    );
};

export default NavbarHelpMenu;
