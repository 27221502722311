import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const AlertDialog = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.cancelClicked}
            aria-labelledby='alert-dialog-slide-title'
            aria-describedby='alert-dialog-slide-description'>
            <DialogTitle id='alert-dialog-slide-title'>{t(props.title)}</DialogTitle>
            <DialogActions>
                <Button onClick={props.cancelClicked} color='primary' variant='outlined'>
                    {t('globals.cancel')}
                </Button>
                <Button onClick={props.acceptClicked} color='primary' variant='contained'>
                    {t('globals.accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
