import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
    name: 'ui',
    initialState: {
        loader:  [],
    },
    reducers: {
        addLoading: (state, action) =>{
            const message = action.payload || "Please wait...";
            return {...state, loader: [...state.loader, message]};
        },
        removeLoading: (state, action) =>{
            const message = action.payload || "Please wait...";
            const index = state.loader.lastIndexOf(message);
            const loaders = [...state.loader];
            loaders.splice(index, 1);
            return {...state, loader: [...loaders]};
        }
    },
});

export const { addLoading, removeLoading } = globalSlice.actions;
export const selectIsLoading = state => state.ui.loader && state.ui.loader.length > 0;

export default globalSlice.reducer;
