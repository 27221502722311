import React, { useState } from 'react';

export const useDialog = () => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return {
        dialogOpen,
        handleDialogOpen,
        handleDialogClose,
    };
};
