import { Box, Button, Menu, MenuItem, Popper } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from '../../../store/globalSlice';
import { useNavbarStyles } from './AppNavbar.style';
import { ExpandMore, ExitToApp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router';
const UserProfile = () => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const user = useSelector(selectLoggedInUser);
    const classes = useNavbarStyles();
    const auth = useAuth();
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () =>{
        auth.logout();
    }

    return  <>
                <Box className={classes.userProfile}>
                    <Button 
                        id="profile-button"
                        endIcon={<ExpandMore />} 
                        color='primary' 
                        variant='outlined'
                        aria-controls={open ? 'profile-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        {user.username} 
                    </Button>
                    <Menu id="profile-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'profile-button',
                        }}
                    >
                        <MenuItem onClick={handleLogout}><ExitToApp />{t('routes.auth.logout')}</MenuItem>
                    </Menu>
                </Box>

            </>

}

export default UserProfile;