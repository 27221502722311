import * as yup from 'yup';
import i18next from '../../../i18n';
import { nameValidator } from './fieldValidators.yup';
import { CBCT, IMAGING2D, INTRAORAL } from '../options/appointmentForm.options';
/**
 * yup utils - schema validation for forms using yup
 */

export const REQUIRED = i18next.t('forms.validators.required');

export const OrderReportValidationSchema = yup.object().shape({});

export const CreateAppointmentValidationSchema = (
    isReferral,
    requiredMessage,
    activeScansCheckboxes
) => {
    let validationShape = {
        patientDetails: yup.object().shape({
            patientName: yup.string().required(requiredMessage),
            dateOfBirth: yup.date().required(requiredMessage),
            gender: yup.string().required(requiredMessage),
            phone: yup.number().min(10).required(requiredMessage),
            mobile: yup.number().min(11).required(requiredMessage),
            payment: yup.string().required(requiredMessage),
            isPossibilityOfPregnancy: yup.string().required(requiredMessage),
        }),
        patientConsents: yup.boolean().oneOf([true], requiredMessage),
        patientAgrees: yup.boolean().oneOf([true], requiredMessage),
    };

    //referral appointment form has some extra fields
    if (isReferral) {
        validationShape.bookingOption = yup.string().required(requiredMessage);
        validationShape.isScannedAfterPeriod = yup.boolean().required(requiredMessage);

        //TODO - when isScannedAfterPeriod, scanDate should be mandatory
        // validationShape.scanDate = yup.date().when('isScannedAfterPeriod', {
        //     is: true,
        //     then: yup.date().required(requiredMessage),
        // });
    }

    //Extra validation added only when relevant checkboxes are ticked.
    //Those checkboxes aren't part of the form so can't use yup's "when"
    if (activeScansCheckboxes.includes(CBCT)) {
        validationShape.cbctDetails = yup.object().shape({
            isComingWithRadiographicTemplate: yup.string().required(requiredMessage),
            cbctAreaOfInterest: yup.object().required(requiredMessage),
            clinicalIndications: yup.string().required(requiredMessage),
            scans: yup.array().min(1),
            outputs: yup.array().min(1),
            justifications: yup.array().min(1),
        });
    }
    if (activeScansCheckboxes.includes(IMAGING2D)) {
        validationShape.imaging2DDetails = yup.object({
            isComingWithRadiographicTemplate: yup.string().required(requiredMessage),
            clinicalIndications: yup.string().required(requiredMessage),
            imaging: yup.array().min(1),
            outputs: yup.array().min(1),
            justifications: yup.array().min(1),
        });
    }
    if (activeScansCheckboxes.includes(INTRAORAL)) {
        validationShape.intraoralDetails = yup.object({
            scanOption: yup.string().required(requiredMessage),

            // only when digitalImpression is selected
            areaOfInterest: yup.string().when('scanOption', {
                is: 'digitalImpression',
                then: yup.string().required(requiredMessage),
            }),
        });
    }

    const validationSchema = yup.object().shape(validationShape);
    return validationSchema;
};

//TODO: i18n
//DIAGNOCAT FORMS
export const ThirdMolarValidationSchema = yup.object().shape({
    teethList: yup.array().min(1, 'Must Select Teeth').max(1, 'Only one teeth').required(REQUIRED),
});

export const STLValidationSchema = yup.object().shape({
    teethList: yup.array().min(1, 'Must Select Teeth').required(REQUIRED),
});

export const EndodonticsImplantologyValidationSchema = yup.object().shape({
    sliceThickness: yup.string().required(REQUIRED),
    sliceSteps: yup.string().required(REQUIRED),
    teethList: yup.array().min(1, 'Must Select Teeth').required(REQUIRED),
});

export const getAuthValidationSchema = (requiredMessage) => {
    return yup.object().shape({
        username: yup.string().required(requiredMessage),
        password: yup.string().required(requiredMessage),
    });
};

export const getRegistrationValidationSchema = (requiredMessage) => {
    return yup.object().shape({
        gdcNumber: yup.string().required(requiredMessage),
        speciality: yup.string().required(requiredMessage),
        firstName: yup.string().required(requiredMessage),
        lastName: yup.string().required(requiredMessage),
        practiceName: yup.string().required(requiredMessage),
        address1: yup.string().required(requiredMessage),
        city: yup.string().required(requiredMessage),
        postcode: yup.string().required(requiredMessage),
        phoneNumber: yup.number().required(requiredMessage),
        email: yup
            .string()
            .required(requiredMessage)
            .email(i18next.t('forms.validators.emailInvalid')),
        retypeEmail: yup
            .string()
            .oneOf([yup.ref('email'), null], 'forms.validators.emailsDontMatch')
            .required(requiredMessage),
        password: yup
            .string()
            .required(requiredMessage)
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
            ),
        retypePassword: yup
            .string()
            .oneOf([yup.ref('password'), null], 'forms.validators.passwordsDontMatch')
            .required(requiredMessage),
        defaultBranch: yup.string().required(requiredMessage),
        howHeardAboutUs: yup.string().required(requiredMessage),
        agreedTerms: yup.boolean().oneOf([true], requiredMessage),
    });
};

export const getPatientFormValidationSchema = (
    requiredMessage,
    fieldTooShortMessage,
    fieldTooLongMessage
) => {
    return yup.object().shape({
        firstName: nameValidator.required(requiredMessage),
        lastName: nameValidator.required(requiredMessage),
        dateOfBirth: yup.date().required(requiredMessage),
        // referringDoctor: yup
        //     .string()
        //     .min(2, fieldTooShortMessage)
        //     .max(50, fieldTooLongMessage)
        //     .required(requiredMessage),
        gender: yup.string().required(requiredMessage),
    });
};

export const getSendEmailFormValidationSchema = (requiredMessage) => {
    return yup.object().shape({
        name: yup.string().required(requiredMessage),
        email: yup
            .string()
            .required(requiredMessage)
            .email(i18next.t('forms.validators.emailInvalid')),
    });
};
