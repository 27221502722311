import React from 'react';
import DiagnocatAnalysis from './DiagnocatAnalysis';
import Typography from '@material-ui/core/Typography';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OrderReportDialog from '../dialogs/OrderReportDialog';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from './DeleteButton';
import { requestAnalysis } from '../../../store/diagnocat/diagnocatSlice';
import { getAnalysisTypeAccordingToStudyType } from '../../../services/diagnocat/diagnocat.service';
import { selectLoggedInUser } from '../../../store/globalSlice';
import withErrorHandler from '../../../hoc/withErrorHandler';
import { RADIOLOGIST_ROLE } from '../../../assets/conf/diagnocat.configuration';

const DiagnocatStudy = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(selectLoggedInUser);

    const [activeDialog, setActiveDialog] = React.useState(null);

    const handleDialogOpen = (dialogName) => {
        setActiveDialog(dialogName);
    };

    const handleDialogClose = () => {
        setActiveDialog(null);
    };

    const orderRadiologicalReport = () => {
        dispatch(
            requestAnalysis(props.study.uid, {
                analysis_type: getAnalysisTypeAccordingToStudyType(props.study.study_type),
            })
        );
        onRequestCompleted();
    };

    const onAnalysisOrder = (analysisRequest) => {
        dispatch(
            requestAnalysis(props.study.uid, {
                analysis_type: analysisRequest.analysis_type,
                analysis_params: analysisRequest.analysis_params,
            })
        );
        onRequestCompleted();
    };

    const onRequestCompleted = () => {
        alert('Analysis started');
        setActiveDialog(null);
    };

    const onDeleteStudyAccepted = () => {
        props.deleteStudy(props.study.uid);
    };

    const onDeleteAnalysis = (analysisUID) => {
        props.deleteAnalysis(props.study.uid, analysisUID);
    };

    return (
        <Box mt={2}>
            <OrderReportDialog
                activeDialog={activeDialog}
                dialogClosed={handleDialogClose}
                dialogOpen={handleDialogOpen}
                studyId={props.study.uid}
                orderRadiologicalReport={orderRadiologicalReport}
                formSubmitted={onAnalysisOrder}
            />
            <Typography variant={'h5'}>
                {props.study.study_type} {props.study.study_date}
            </Typography>
            {/*TODO: currently don't show images because of Diagnocat API bug*/}
            {/*<Box mt={1}>*/}
            {/*    <Typography variant={'h6'}>{t('containers.diagnocat.images')}:</Typography>*/}
            {/*    {props.study.images &&*/}
            {/*        props.study.images.map((image) => (*/}
            {/*            <DiagnocatImage key={image.uid} image={image} />*/}
            {/*        ))}*/}
            {/*</Box>*/}
            <Box mt={1}>
                <Typography variant={'h6'}>{t('containers.diagnocat.analyses')}:</Typography>
                {props.study.analyses &&
                    props.study.analyses.map((analysis) =>
                        loggedInUser.roleId === RADIOLOGIST_ROLE ? (
                            analysis.analysis_type === 'GP' && (
                                <DiagnocatAnalysis
                                    key={analysis.uid}
                                    analysis={analysis}
                                    deleteAnalysis={onDeleteAnalysis}
                                />
                            )
                        ) : (
                            <DiagnocatAnalysis
                                key={analysis.uid}
                                analysis={analysis}
                                deleteAnalysis={onDeleteAnalysis}
                            />
                        )
                    )}
            </Box>
            {
                loggedInUser.roleId !== RADIOLOGIST_ROLE && (
                    <Box display='flex' mt={2} mb={2} style={{gap: 12}}>
                        <Button
                            variant='contained'
                            color='primary'
                            size='medium'
                            onClick={() => handleDialogOpen('orderReportDialog')}>
                            {t('containers.diagnocat.orderAnalysis')}
                        </Button>
                        <DeleteButton
                            acceptClicked={onDeleteStudyAccepted}
                            style={{ marginLeft: '2em' }}
                            name={t('containers.diagnocat.deleteStudy')}
                        />
                    </Box>
                )
            }
        </Box>
    );
};

export default withErrorHandler(DiagnocatStudy);
