import diagnocatInstance from '../../axios/diagnocatAxios';
import {
    DIAGNOCAT_GET_APPOINTMENT,
} from '../../assets/conf/api-endpoints';
import _ from 'lodash';

//TODO - split to different functions with fieldName as part of te function, for example: updateAppointmentRadiologist
export async function updateAppointment(appointmentId, fieldName, fieldValue) {
    let req = {
        fields: [
            {
                name: fieldName,
                value: fieldValue,
            }
        ]
    };
    const response = await diagnocatInstance.put(`${DIAGNOCAT_GET_APPOINTMENT}/${appointmentId}`, req);
    return response.data;
}

/**
 * Currently supports only 1 appointment per patient
 */
export async function getAppointmentByPatientId(patientId) {
    const response = await diagnocatInstance.get(`${DIAGNOCAT_GET_APPOINTMENT}?offset=0&limit=1&patientId=${patientId}&orderBy=created_at&orderDirection=DESC`);
    return response.data.data ? response.data.data[0] : null;
}

/**
 * get appointment data by appointment id
 * @param {*} id 
 * @returns 
 */
export async function getAppointmentById(id) {
    const response = await diagnocatInstance.get(`${DIAGNOCAT_GET_APPOINTMENT}/${id}`);
    return response.data.data ? response.data.data : null;
}