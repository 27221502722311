import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import ReportDetailsDialog from './ReportDetailsDialog';
import DialogActions from '@material-ui/core/DialogActions';
import { DIALOG_TYPES, REPORT_TYPES } from '../../../assets/conf/diagnocat.configuration';
import { useTranslation } from 'react-i18next';
import withErrorHandler from '../../../hoc/withErrorHandler';

const OrderReportDialog = (props) => {
    const { t } = useTranslation();
    const [selectedReport, setSelectedReport] = React.useState({});

    const openReportDetailsDialog = (reportType) => {
        setSelectedReport(reportType);
        props.dialogOpen(DIALOG_TYPES.REPORT_DETAILS);
    };

    return (
        <React.Fragment>
            <ReportDetailsDialog
                activeDialog={props.activeDialog}
                dialogClosed={props.dialogClosed}
                reportType={selectedReport}
                studyId={props.studyId}
                formSubmitted={props.formSubmitted}
            />
            <Dialog
                open={props.activeDialog === DIALOG_TYPES.ORDER_REPORT}
                onClose={props.dialogClosed}
                aria-labelledby='form-dialog-title'
                fullWidth
                style={{ textAlign: 'center' }}
                maxWidth='xs'>
                <DialogTitle id='create-appointment-form-dialog-title'>
                    {'Order Report'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {'Select the report you are interested in and order it.'}
                    </DialogContentText>
                    <Box
                        display='flex'
                        flexDirection='column'
                        p={2}
                        style={{ width: '80%', margin: 'auto' }}>
                        <Button
                            style={{ marginBottom: '0.75em' }}
                            variant='contained'
                            color='default'
                            size='large'
                            onClick={props.orderRadiologicalReport}>
                            {t('containers.diagnocat.reports.radiological')}
                        </Button>
                        <Button
                            style={{ marginBottom: '0.75em' }}
                            variant='contained'
                            color='default'
                            size='large'
                            onClick={() => openReportDetailsDialog(REPORT_TYPES.ENDODONTICS)}>
                            {t('containers.diagnocat.reports.endodontic')}
                        </Button>
                        <Button
                            style={{ marginBottom: '0.75em' }}
                            variant='contained'
                            color='default'
                            size='large'
                            onClick={() => openReportDetailsDialog(REPORT_TYPES.IMPLANTOLOGY)}>
                            {t('containers.diagnocat.reports.implantology')}
                        </Button>
                        <Button
                            style={{ marginBottom: '0.75em' }}
                            variant='contained'
                            color='default'
                            size='large'
                            onClick={() => openReportDetailsDialog(REPORT_TYPES.THIRD_MOLAR)}>
                            {t('containers.diagnocat.reports.thirdMolar')}
                        </Button>
                        <Button
                            variant='contained'
                            color='default'
                            size='large'
                            onClick={() => openReportDetailsDialog(REPORT_TYPES.STL)}>
                            {t('containers.diagnocat.reports.stl')}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions style={{justifyContent: 'flex-start'}}>
                    <Button onClick={props.dialogClosed} color='primary'>
                        {t('globals.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default withErrorHandler(OrderReportDialog);
