import React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UploadFilesForm from './UploadFilesForm';
import { DIALOG_TYPES } from '../../../assets/conf/diagnocat.configuration';
import { useTranslation } from 'react-i18next';

const UploadFileDialog = (props) => {
    const { t } = useTranslation();

    const onUploadFinished = (patientId) => {
        setTimeout(function () {
            alert(t('containers.diagnocat.uploads.uploadSucceed'));
            props.dialogClosed();
            props.choosePatient(patientId);
        }, 3000);
    };

    return (
        <Dialog
            open={props.activeDialog === DIALOG_TYPES.UPLOAD}
            onClose={props.dialogClosed}
            aria-labelledby='form-dialog-title'
            style={{ textAlign: 'center' }}
            fullWidth
            maxWidth='xs'>
            <IconButton
                aria-label="close"
                onClick={props.dialogClosed}
                style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogTitle id='create-appointment-form-dialog-title'>
                {' '}
                {t('containers.diagnocat.uploads.uploadFile')}
            </DialogTitle>
            <DialogContent>
                <UploadFilesForm uploadFinished={onUploadFinished} closeDialog={props.dialogClosed} />
            </DialogContent>
            <Box mb={5}>
            </Box>
        </Dialog>
    );
};

export default UploadFileDialog;
