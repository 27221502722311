import React, { useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { useStyles } from './AppDrawer.style';
import MenuItems from './MenuItems';
import { diagnocatRoutes } from '../../../routing/routes/routes';
import { DiagnocatContext } from '../../../App';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import withErrorHandler from '../../../hoc/withErrorHandler';

const AppDrawer = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isDiagnocatMode = useContext(DiagnocatContext);

    //TODO - according to roles
    let activeRoutes =  diagnocatRoutes;
    let activeResourceRoutes = [];


    return (
        <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='left'
            open={props.open}
            classes={{
                paper: classes.drawerPaper,
            }}>
            <Divider />
            <MenuItems routes={activeRoutes} menuItemClicked={props.closeDrawerClicked} />
            {activeResourceRoutes.length > 0 && (
                <>
                    <Divider />
                    <Typography align='left' style={{ padding: '0.3em', color: 'grey' }}>
                        {t('globals.operations')}
                    </Typography>
                    <MenuItems routes={activeResourceRoutes} />
                </>
            )}
        </Drawer>
    );
};

export default withErrorHandler(AppDrawer);
