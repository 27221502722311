import React from 'react';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { logoutUser, setLoggedInUser } from '../store/globalSlice';
import { useHistory } from 'react-router';
import { AUTH_DATA_STORAGE } from '../assets/conf/configurations';

const useAuth = () =>{
    const dispatch = useDispatch();
    const history = useHistory();

    const setLoggedIn = (data) =>{
        const payload = jwtDecode(data.token);
        localStorage.removeItem(AUTH_DATA_STORAGE);

        if (payload?.exp){
            const expiration = new Date(payload.exp * 1000);
            const today = new Date();

            if (expiration > today){

                localStorage.setItem(AUTH_DATA_STORAGE, JSON.stringify(data));
                dispatch(setLoggedInUser(data));
                setTimeout(()=>{
                    logout();
                }, expiration - today);
            }
        }
    }

    const logout = () =>{
        localStorage.removeItem(AUTH_DATA_STORAGE);
        dispatch(logoutUser());
    }
    
    const reloadAuthData = () =>{
        const rawAuthData = localStorage.getItem(AUTH_DATA_STORAGE);

        if (rawAuthData){
            const authData = JSON.parse(rawAuthData);
            setLoggedIn(authData);
        }
    }


    return {setLoggedIn, logout, reloadAuthData};
}

export default useAuth;