import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        role: 'doctor',
        locale: 'en',
        branch: {
            id: 1,
            value: 'london',
            name: 'London',
            address: 'Mock London Address',
            dayStart: '08:00:00',
            dayEnd: '20:00:00',
            slotDuration: '00:05:00',
            hiddenDays: [0],
        },
        loggedInUser: null,
        token: null,
        auth: null,
    },

    reducers: {
        hydrate:(state, action) => {
            // do not do state = action.payload it will not update the store
            console.log("hydrate", action.payload);
            return action.payload
        },
        setRole: (state, action) => {
            // state.role = action.payload.role;

            return {...state, role: action.payload.role}
        },
        setBranch: (state, action) => {
            // state.branch = action.payload.branch;
            return {...state, branch: action.payload.branch}
        },
        setLocale: (state, action) => {
            return {...state, loocale: action.payload.locale}
            // state.locale = action.payload.locale;
        },
        setLoggedInUser: (state, action) => {
            // state.loggedInUser = action.payload.user;
            // state.auth = action.payload;
            return {...state, loggedInUser: action.payload.user, auth: action.payload, token: action.payload.token}
        },
        logoutUser: (state) =>{
            return {...state, loggedInUser: null, auth: null, token: null}
        }
    },
});

export const { setRole, setBranch, setLocale, setLoggedInUser, logoutUser, hydrate } = globalSlice.actions;

export const selectRole = (state) => state.global.role;
export const selectBranch = (state) => state.global.branch;
export const selectLocale = (state) => state.global.locale;
export const selectLoggedInUser = (state) => state.global.loggedInUser;
export const selectAuth = state => state.global.auth;
export const selectAuthToken = state => state.global.token;
export const selectIsAuthenticated = state => {
    const {auth} = state.global;
    console.log("auth", auth);

    if (!auth) return false;

    const payload = jwtDecode(auth.token);
    if (payload?.exp){
        const expiration = new Date(payload.exp * 1000);
        const today = new Date();
        return expiration > today;
    }
    return true;
};

export default globalSlice.reducer;
