import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import './i18n';

ReactDOM.render(
    <React.StrictMode>
        <HttpsRedirect>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </HttpsRedirect>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your store to work offline and load faster, you can change
// unregister() to sign-up() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
