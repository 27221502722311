import React from 'react';
import { Box, Paper } from '@material-ui/core';
import AppSpinner from '../../components/UI/spinner/AppSpinner';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { selectActiveSessions } from '../../store/diagnocat/uploadSlice';
import { useTranslation } from 'react-i18next';
import { getFullName } from '../../utils/globals.utils';
import { IDLE } from '../../assets/conf/configurations';

const DiagnocatActiveSessions = () => {
    const activeSessions = useSelector(selectActiveSessions);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Typography>{t('containers.diagnocat.uploads.activeSessions')}</Typography>
            {activeSessions.map((activeSession) => (
                <Box mt={2}>
                    <Paper>
                        {activeSession.patient && (
                            <Typography>
                                {getFullName(
                                    activeSession.patient.firstName,
                                    activeSession.patient.lastName
                                )}
                            </Typography>
                        )}
                        {activeSession.loading !== IDLE && (
                            <Box mt={2}>
                                <AppSpinner />
                            </Box>
                        )}
                        <Box mt={2}>
                            <Typography>{t(activeSession.message)}</Typography>
                            <Typography>{activeSession.error && t(activeSession.error)}</Typography>
                        </Box>
                    </Paper>
                </Box>

            ))}
        </React.Fragment>
    );
};

export default DiagnocatActiveSessions;
