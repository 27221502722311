import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../../../utils/constants';

export const useFooterStyles = makeStyles((theme) => ({
    appFooter: {
        padding: theme.spacing(2),
        color: 'white',
        fontSize: '0.75rem',
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        flex: '1 0 auto',
    },

    appFooterShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: DRAWER_WIDTH,
    },
}));
