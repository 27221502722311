import * as yup from 'yup';
import i18next from '../../../i18n';

/**
 * yup utils - field validation for forms using yup
 */

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 50;

export const emailValidator = yup.string().email(i18next.t('forms.validators.emailInvalid'));

export const nameValidator = yup.string()
    .min(NAME_MIN_LENGTH, i18next.t('forms.validators.fieldTooShort'))
    .max(NAME_MAX_LENGTH, i18next.t('forms.validators.fieldTooLong'));


//     maxDays: yup
//         .number('Enter Days Number')
//         .min(minDays, `Minimum Days is ${minDays}`)
//         .max(maxDays, `Maximum Days is ${maxDays}`)
//         .required('Maximum Days is required'),
// });
