import React from 'react';
import FormDialog from '../../../components/UI/dialog/FormDialog';
import EndodonticsImplantologyReportForm from './forms/EndodonticsImplantologyReportForm';
import ThirdMolarReportForm from './forms/ThirdMolarReportForm';
import STLReportForm from './forms/STLReportForm';
import { ANALYSIS_TYPES, DIALOG_TYPES, REPORT_TYPES } from '../../../assets/conf/diagnocat.configuration';
import { useTranslation } from 'react-i18next';

const ReportDetailsDialog = (props) => {
    const { t } = useTranslation();

    const renderReportForm = () => {
        switch (props.reportType) {
            case REPORT_TYPES.ENDODONTICS:
                return (
                    <EndodonticsImplantologyReportForm
                        handleClose={props.dialogClosed}
                        studyId={props.studyId}
                        type={ANALYSIS_TYPES.ENDO}
                        formSubmitted={props.formSubmitted}
                    />
                );
            case REPORT_TYPES.IMPLANTOLOGY:
                return (
                    <EndodonticsImplantologyReportForm
                        handleClose={props.dialogClosed}
                        studyId={props.studyId}
                        type={ANALYSIS_TYPES.IMPLANT}
                        formSubmitted={props.formSubmitted}
                    />
                );
            case REPORT_TYPES.THIRD_MOLAR:
                return (
                    <ThirdMolarReportForm
                        handleClose={props.dialogClosed}
                        formSubmitted={props.formSubmitted}
                    />
                );
            case REPORT_TYPES.STL:
                return (
                    <STLReportForm
                        handleClose={props.dialogClosed}
                        formSubmitted={props.formSubmitted}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <FormDialog
            title={t(props.reportType.title)}
            contextText={t(props.reportType.content)}
            open={props.activeDialog === DIALOG_TYPES.REPORT_DETAILS}
            maxWidth='md'
            handleClose={props.dialogClosed}>
            {renderReportForm()}
        </FormDialog>
    );
};

export default ReportDetailsDialog;
